import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import "./styleTable.css";

export default function ConfirmRequest({ data, isLoading }) {
  const navigate = useNavigate();
  const [currentTeachers, setCurrentTeachers] = useState([]);
  const [selectedteacherId, setSelectedTeacherId] = useState();
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [isLogining, setIslogining] = useState(false);

  useEffect(() => {
    console.log("data from react query", data?.data);
    setCurrentTeachers(data?.data);
  }, [data]);

  const handleDeleteTeacher = (e, id) => {
    setIsLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/delete_teacher_by_id/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        console.log(
          "modal show",
          document.getElementsByClassName("modal-backdrop")[0]
        );
        if (data.status === 200) {
          setSuccessMessage("تمت  عملية الحذف بنجاح");

          modalRef.current.style.display = "none";
          // closeModalBtn.current.click();
          console.log("modal show");
          document.getElementsByClassName("modal-backdrop")[0].remove();

          setIsLoading(false);
          console.log(data);

          const newCurrentTeachers = currentTeachers.filter(
            (currentTeacher) => currentTeacher.teacher_id != id
          );

          setCurrentTeachers([...newCurrentTeachers]);
        }
      })
      .catch((error) => {
        console.log(error);
        // setErrorMessage("غير متصل بالشبكه");
        setIsLoading(false);
        // setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')

        modalRef.current.style.display = "none";
        closeModalBtn.current.click();
      });
  };

  const selectedTeacherId = (id) => {
    console.log("id", id);
    setSelectedTeacherId(id);
  };

  const handleloginAsTeacher = (teacherId) => {
    setIslogining(true);

    const dataToSend = {
      id: teacherId,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login_with_teacher_id`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log("token is data ", data?.data.data.token);

        //setSuccessMessage('تمت العملية بنجاح .. سيتم توجيهك في غضون ثوان')

        window.open(
          `${process.env.REACT_APP_FRONT_APP_URL}/loginasteacher/${data?.data.data.token}`
        );
      })
      .catch((error) => {
        console.log("error login as teacher", error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        setIslogining(false);
      });
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> اسم الاستاذ</th>
              <th className="text-center align-middle"> اسم المستخدم</th>
              <th className="text-center align-middle"> الايميل</th>
              <th className="text-center align-middle"> رقم الهاتف</th>
              <th className="text-center align-middle"> نوع الأستاذ </th>
              <th className="text-center align-middle">العمليات المتاحة</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={5}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : currentTeachers && currentTeachers.length > 0 ? (
              currentTeachers.map((teacher, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(`/teacher/${teacher.teacher_id}`);
                    }}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">
                      <a
                        target={"_blank"}
                        href={`https://admin.myway.edu.sy/#/teacher/${teacher.teacher_id}`}
                      >
                        {teacher.teacher_name}
                      </a>
                    </td>
                    <td className="text-center align-middle">
                      {teacher.username}
                    </td>
                    <td className="text-center align-middle">
                      {teacher.email}
                    </td>
                    <td className="text-center align-middle">
                      {teacher.phone_number}
                    </td>
                    <td className="text-center align-middle">
                      {teacher.type == "MAIN" ? "أساسي" : "مساعد"}
                    </td>
                    <td className="text-center align-middle">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={isLogining}
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleloginAsTeacher(teacher.teacher_id);
                        }}
                      >
                        <div>

                        تسجيل الدخول بحساب الاستاذ
                        </div>
                      </button>

                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => {
                          e.stopPropagation();
                          selectedTeacherId(teacher.teacher_id);
                        }}
                      >
                        <div className="px-3">

                        حذف
                        </div>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={5} className="text-center w-100">
                <h3>لم يتم اضافة اساتذة بعد</h3>{" "}
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        ref={modalRef}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري الحذف ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>

            {Isloading && (
              <div className="modal-body">
                {Isloading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {!Isloading && (
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss={Isloading ? "" : "exampleModal"}
                  onClick={(e) => handleDeleteTeacher(e, selectedteacherId)}
                >
                  حذف
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

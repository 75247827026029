import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styleTable.css";
import right from "../../assets/students/right.png";
import wrong from "../../assets/students/wrong.jpg";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";

export default function ConfirmRequest({ data, isLoading }) {
  const navigate = useNavigate();
  const [currentTeachers, setCurrentTeachers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [selectedStudentId, setSelectedStudent] = useState();
  const [newPassword, setNewPassWord] = useState("");
  const newPassWordModalRef = useRef(null);
  const pointsModalRef = useRef(null);
  const [newPoints, setNewPoints] = useState(0);
  const [reason, setReason] = useState("");
  const [Increase, setIncrease] = useState(true);
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [IsChaneModeloading, setIsChaneModeloading] = useState(false);

  console.log(data);

  useEffect(() => {
    console.log("data from react query", data?.data);
    setCurrentTeachers(data?.data);
  }, [data]);

  const handleLoginAsStudent = (event, student) => {
    event.stopPropagation();
    setIsLoading(true);

    const dataToSend = {
      id: student.student_id,
    };

    console.log("student is", student);
    console.log("data to send is", dataToSend);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login_with_student_id`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log("token is data ", data?.data.data.token);

        setSuccessMessage("تمت العملية بنجاح .. سيتم توجيهك في غضون ثوان");

        setTimeout(() => {
          window.open(
            `${process.env.REACT_APP_FRONT_APP_URL}/loginasstudent/${data?.data.data.token}/${student.is_active}`
          );
        }, 1500);
      })
      .catch((error) => {
        console.log("error login as teacher", error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deActiveStudentAccount = (event, studentId) => {
    event.stopPropagation();

    // setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/de_active_student_account_by_id/${studentId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        // console.log('token is data ' , data?.data.data.token)

        setSuccessMessage("تم إلغاء تفعيل الحساب");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // window.open(`https://myway.edu.sy/loginasteacher/${data?.data.data.token}`);
      })
      .catch((error) => {
        // console.log('error login as teacher', error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        // modalRef.current.style.display = 'none';
        closeModalBtn.current.click();
        // setIsLoading(false);
      });
  };

  const handleselectStudent = (event, studentId) => {
    event.stopPropagation();
    setSelectedStudent(studentId);
  };

  const changePassword = (studentId) => {
    // setIsLoading(true);

    if (newPassword == "") {
      setErrorMessage(
        "الرجاء ادخال كلمة السر ومن ثم قم بحقظ التعديلات مرة اخرى"
      );
    } else {
      const data = {
        password: newPassword,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/${studentId}/password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // console.log('token is data ' , data?.data.data.token)

          setSuccessMessage("تم تعديل كلمة السر بنجاح");
        })
        .catch((error) => {
          // console.log('error login as teacher', error);
          setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
        })
        .finally(() => {
          // modalRef.current.style.display = 'none';
          newPassWordModalRef.current.click();
          // setIsLoading(false);
        });
    }
  };

  const changeWatchMode = (student) => {
    setIsChaneModeloading(true);

    const watchMode =student.watch_mode == 'S3' ? 'YOUTUBE' : 'S3' 
    const data = {
      watch_mode: watchMode ,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update/student/${student.student_id}/watch-mode`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => {
        // console.log('token is data ' , data?.data.data.token)
        setCurrentTeachers((prevStudents) =>
          prevStudents.map((s) =>
            s.student_id === student.student_id
              ? { ...s, watch_mode: watchMode } // Update watch_mode for the specific student
              : s
          )
        );
        setSuccessMessage("تم تعديل وضع حضور الفيديوهات للطالب " + student.name + " بنجاح");
      })
      .catch((error) => {
        // console.log('error login as teacher', error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        setIsChaneModeloading(false);
      });
  }

  const updateStudentPoints = (studentId) => {
    // setIsLoading(true);

    if (newPoints == "" || reason == "") {
      setErrorMessage("الرجاء إدخال النقاط والسبب");
    } else {
      const type = Increase ? "INCREASE" : "DECREASE";

      const data = {
        reason: reason,
        points: parseInt(newPoints),
        type: type,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/students/${studentId}/manage/points`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // console.log('token is data ' , data?.data.data.token)

          setSuccessMessage("تم تعديل نقاط الطالب بنجاح ");
        })
        .catch((error) => {
          // console.log('error login as teacher', error);
          setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
        })
        .finally(() => {
          // modalRef.current.style.display = 'none';
          pointsModalRef.current.click();
          // setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    console.log("increase ", Increase);
  }, [Increase]);

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
<div className="overflow-auto w-100">
  <table
    className="table table-striped table-min-fit table-hover shadow rounded border mt-3"
    style={{ minHeight: "100px" }}
  >
    <thead>
      <tr className="align-middle table-head fw-bold text-white bg-primary">
        <th className="text-center align-middle">الاسم</th>
        <th className="text-center align-middle">اسم المستخدم</th>
        <th className="text-center align-middle">آخر نشاط للطالب</th>
        <th className="text-center align-middle">النقاط</th>
        <th className="text-center align-middle">القسم الذي ينتمي إليه الطالب</th>
        <th className="text-center align-middle">حالة الحساب</th>
        <th className="text-center align-middle">تاريخ الإنشاء</th>
        <th className="text-center align-middle">العمليات المتاحة</th>
        <th className="text-center align-middle">رقم هاتف الطالب</th>
        <th className="text-center align-middle">رقم هاتف ولي الأمر</th>
      </tr>
    </thead>

    <tbody style={{ minHeight: "400px" }}>
      {isLoading ? (
        <tr>
          <td colSpan={12}>
            <div className="w-100 text-center my-5 d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        currentTeachers &&
        currentTeachers.map((student, index) => (
          <tr
            key={index}
            style={{
              cursor: "pointer",
              borderBottom: "1px solid #1a1a1a1a",
            }}
            className="tableRowHover"
          >
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.name}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.username}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.last_active_at}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.points}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.section_name}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle activatOrNot"
            >
              {student.is_active == 0 ? (
                <img
                  src={wrong}
                  alt="Inactive"
                  style={{
                    width: "24px",
                    height: "24px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={right}
                  alt="Active"
                  style={{
                    width: "24px",
                    height: "24px",
                    objectFit: "contain",
                  }}
                />
              )}
            </td>
            <td className="text-center align-middle activatOrNot">
              {student.created_at}
            </td>

            <td className="text-center align-middle">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    navigate(`/SubjectStudent/${student.student_id}`)
                  }
                >
                  عرض المواد
                </button>
                {student.watch_mode === "YOUTUBE" ? (
                  <button
                    className="btn btn-warning"
                    onClick={() => changeWatchMode(student)}
                    disabled={IsChaneModeloading}
                  >
                    {IsChaneModeloading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "قلب الحضور إلى S3"
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={() => changeWatchMode(student)}
                    disabled={IsChaneModeloading}
                  >
                    {IsChaneModeloading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "قلب الحضور إلى يوتيوب"
                    )}
                  </button>
                )}
                <button
                  className="btn btn-info text-white"
                  onClick={() => navigate(`/callsRecord/${student.student_id}`)}
                >
                  سجل المكالمات
                </button>
                {localStorage.getItem("role_id") != 9 && (
                  <>
                    <button
                      className="btn btn-secondary"
                      onClick={() =>
                        navigate(`/editstudentdetails/${student.student_id}`)
                      }
                    >
                      تعديل معلومات الطالب
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalpassword"
                      onClick={() => setSelectedStudent(student.student_id)}
                    >
                      تعديل كلمة السر للطالب
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalpoints"
                      onClick={() => {
                        setSelectedStudent(student.student_id);
                        setNewPoints(student.points);
                      }}
                    >
                      التحكم بنقاط الطالب
                    </button>
                    <button
                      className="btn btn-success"
                      disabled={Isloading}
                      onClick={(e) => handleLoginAsStudent(e, student)}
                    >
                      تسجيل الدخول بحساب الطالب
                    </button>
                  </>
                )}
                <button
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={(e) => handleselectStudent(e, student)}
                >
                  إلغاء تفعيل الحساب
                </button>
              </div>
            </td>

            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.phone_number}
            </td>
            <td
              onClick={() => {
                navigate(`/student/${student.student_id}`);
              }}
              className="text-center align-middle"
            >
              {student.gaurdian_number}
            </td>
          </tr>
        ))
      )}
    </tbody>
  </table>
</div>


      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري إلغاء تفعيل الحساب ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>

            {Isloading && (
              <div className="modal-body">
                {Isloading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {!Isloading && (
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) =>
                    deActiveStudentAccount(e, selectedStudentId.student_id)
                  }
                >
                  إلغاء التفعيل
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Edit student password modal */}
      {localStorage.getItem("role_id") != 9 && (
        <div
          class="modal fade"
          id="exampleModalpassword"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  تعديل كلمة السر
                </h5>
                <button
                  type="button"
                  class="btn-close ml-0  mr-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={newPassWordModalRef}
                ></button>
              </div>
              <div class="modal-body">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    كلمة السر الجديدة
                  </span>
                  <input
                    value={newPassword}
                    onChange={(e) => setNewPassWord(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="كلمة السر"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  إلغاء
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => changePassword(selectedStudentId)}
                >
                  حفظ التعديلات
                  {Isloading && (
                    <div role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit student points modal */}
      {localStorage.getItem("role_id") != 9 && (
        <div
          class="modal fade"
          id="exampleModalpoints"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  التحكم بنقاط الطالب
                </h5>
                <button
                  type="button"
                  class="btn-close ml-0  mr-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={pointsModalRef}
                ></button>
              </div>
              <div class="modal-body">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    نقاط الطالب
                  </span>
                  <input
                    value={newPoints}
                    onChange={(e) => setNewPoints(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>

                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    السبب
                  </span>
                  <input
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="السبب"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>

                <div class="input-group mb-3">
                  <span class="input-group-text px-3 mx-4" id="basic-addon1">
                    زيادة
                  </span>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={Increase}
                    onChange={(e) => setIncrease(e.target.checked)}
                  />
                </div>

                <div class="input-group mb-3">
                  <span class="input-group-text px-3 mx-4" id="basic-addon1">
                    نقصان
                  </span>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={!Increase}
                    onChange={(e) => setIncrease(!e.target.checked)}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  إلغاء
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => updateStudentPoints(selectedStudentId)}
                >
                  حفظ التعديلات
                  {Isloading && (
                    <div role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export default function SubjectsSubSections({
  data,
  isLoading,
  section_subSection,
}) {
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);

  const navigate = useNavigate();

  const handleDeleteSubject = (id) => {
    console.log("idddddd subject", id);
    const temp = [...dataSubject];
    const DataDelte = temp.filter((item) => item.id !== id);
    console.log("Data Deleteeed", DataDelte);
    setDataSubject(DataDelte);
    setFlag(1);
  };

  useEffect(() => {
    setDataSubject(data);
  }, [data]);

  async function deletePost(id) {
    console.log("iddddd", id);

    const res = axios
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_subject_by_subject_id/${id}`,
        {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          console.log("data delete Subscribtion", data);
          window.location.reload();
          handleDeleteSubject(id);
          return res;
        } else {
          console.log(res);
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  // setStatus('Delete successful');
  const [idItem, setIdItem] = useState();
  const handleDelete = (id) => {
    // DELETE request using fetch with async/await
    console.log("no id", id);
    setIdItem(id);

    // handleDeleteSubject(id);
  };
  const handleDeleteItem = () => {
    deletePost(idItem);
  };

  const AddSubjects = () => {
    navigate("/subject", {
      state: {
        sectionId: section_subSection.sectionId,
        subSectionId: section_subSection.subSectionId,
      },
    });
  };

  return (
    <>
      <div className="text-center my-1">
        {/* <Link to={`/addSubSections/${idSub}`}> */}
        <button className="btn btn-primary" onClick={AddSubjects}>
          أضف مادة
        </button>
        {/* </Link> */}
      </div>

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border w-75  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold">
              <th className="text-center w-25 align-middle text-dard fw-bold">
                {" "}
                اسم المادة
              </th>
              <th className="text-center w-75  align-middle text-dark fw-bold">
                {" "}
                العمليات
              </th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={2}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : dataSubject ? (
              dataSubject.length > 0 &&
              dataSubject?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.subject_name}
                    </td>
                    <td className="d-flex justify-content-center gap-4 border-0 mt-1 text-center align-middle">
                      {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                      {/* <Link to={`/subject/${item?.subject_id}`} className="ms-3 btn btn-primary">الوحدات</Link> */}
                      <Link
                        to={`/showComments/${item?.subject_id}`}
                        className="ms-3 btn btn-info"
                      >
                        <div className="px-3">التعليقات</div>
                      </Link>
                      <Link
                        to={`/showFaqs/${item?.subject_id}`}
                        className="ms-3 btn btn-success"
                      >
                        <div className="px-3">الاسئلة الشائعة</div>
                      </Link>
                      <Link
                        to={`/subject/${item?.subject_id}`}
                        state={{
                          sectionId: section_subSection.sectionId,
                          subSectionId: section_subSection.subSectionId,
                        }}
                        className="ms-3 btn btn-warning"
                      >
                        <div className="px-3">تعديل </div>
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.subject_id)}
                        className="ms-3 btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <div className="px-3">حذف</div>
                      </button>
                      <Link
                        to={`/teahcersSubject/${item?.subject_id}`}
                        className="ms-3 btn btn-dark"
                      >
                        <div className="px-3">الاساتذه</div>
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={2} className="text-center align-middle">
                <h3>لايوجد مواد</h3>
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              هل انت متاكد انك تريد حذف هذه المادة
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteItem}
              >
                نعم
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

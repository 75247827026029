import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Layout from "../Layout/Layout";
import Pagination from "../Pagination/Pagination";
import ShowCommentsSubjectsLogic from "./ShowCommentsSubjectsLogic";
import TableCommentsSubjects from "../ConfirmRequest/TableCommentsSujects";
const ShowCommentsSubjects = () => {
  const params = useParams();
  const id = params?.id;

  const { listRef, isLoading, isError, data, error, handlePageClick, page } =
    ShowCommentsSubjectsLogic(id);
  const dataSubject = data?.data?.data;

  return (
    <>
      <Layout>
        <div className="container all">
          <h1 className="pt-0  pb-1 pt-1"> التعليقات</h1>


          {
            <TableCommentsSubjects
              data={dataSubject}
              isLoading={isLoading}
              subjectId={id}
            />
          }

          {data?.total < 10 ? (
            <> </>
          ) : (
            <Pagination
              data={data?.data}
              handlePageClick={handlePageClick}
              page={page}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default ShowCommentsSubjects;

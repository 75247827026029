import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styleTable.css";
import right from "../../assets/students/right.png";
import wrong from "../../assets/students/wrong.jpg";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import { useQuery } from "react-query";
import fetchOpenUnitsForStudent from "../../redux-toolkit/api/fetchOpenUnitsForStudent";
import UnitsForStudentTable from "./UnitsForStudentTable";
import { set } from "lodash";
import CloseIcon from "../../assets/students/close.svg";

export default function StudentMessageTable({
  data,
  isLoading,
  selectedStudents,
  setSelectedStudents,
}) {
  const navigate = useNavigate();
  const [currentTeachers, setCurrentTeachers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [selectedStudentId, setSelectedStudent] = useState();
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [selectedStudentForOpenUnits, setSelectedStudentForOpenUnits] =
    useState(null);

  const [selectedUnitsForCancel, setSelectedUnitsForCancel] = useState([]);
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  useEffect(() => {
    setCurrentTeachers(data?.data);
  }, [data]);

  const handleLoginAsStudent = (event, student) => {
    event.stopPropagation();
    setIsLoading(true);

    const dataToSend = {
      id: student.student_id,
    };

    console.log("student is", student);
    console.log("data to send is", dataToSend);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/login_with_student_id`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log("token is data ", data?.data.data.token);

        setSuccessMessage("تمت العملية بنجاح .. سيتم توجيهك في غضون ثوان");

        setTimeout(() => {
          window.open(
            `${process.env.REACT_APP_FRONT_APP_URL}/loginasstudent/${data?.data.data.token}/${student.is_active}`
          );
        }, 1500);
      })
      .catch((error) => {
        console.log("error login as teacher", error);
        setErrorMessage("حدثت مشكلة ما .. يرجى المحاولة مرة اخرى");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleselectStudent = (event, studentId) => {
    event.stopPropagation();
    setSelectedStudent(studentId);
  };

  const handleAddDeleteStudent = (event, clickedStudent) => {
    // console.log(event.target.checked , clickedStudent);

    // get the previous array values and store it
    const Currentstudents = selectedStudents;
    console.log("current students ", Currentstudents);
    // check if the student is exist in the selected students array
    const isExist = selectedStudents.some(
      (student) => student.student_id == clickedStudent.student_id
    );
    console.log("is exist", isExist);
    // check if the student is selected or not
    const isSelected = event.target.checked;
    console.log("is selected", isSelected);

    // if it is not exist and is checked push it to the array
    if (!isExist && isSelected) {
      console.log("adding the student");
      console.log("array added the studdent", [
        ...Currentstudents,
        { ...clickedStudent },
      ]);
      setSelectedStudents([...Currentstudents, { ...clickedStudent }]);
    }

    // if the student exists and is not selected then remove it from the array
    if (isExist && !isSelected) {
      console.log("removing the student");
      const newCurrentStudent = Currentstudents.filter(
        (student) => student.student_id != clickedStudent.student_id
      );
      setSelectedStudents([...newCurrentStudent]);
    }
  };

  const {
    data: dataUnitsForStudent,
    isLoading: isLoadingUnits,
    isError,
    refetch: refetchStudentUnits,
  } = useQuery(
    ["student_units", selectedStudentForOpenUnits],
    fetchOpenUnitsForStudent,
    { cacheTime: 0 }
  );

  const deleteUnitFromArray = (unitClicked) => {
    const newUnitsArray = selectedUnitsForCancel.filter(
      (unit, idx) => unit.id != unitClicked.id
    );

    setSelectedUnitsForCancel([...newUnitsArray]);
  };

  const handleCancelUnitsForStudent = () => {
    setIsLoading(true);

    const dataToSend = {
      units_ids: selectedUnitsForCancel.map((unit) => unit.id),
      student_id: selectedStudentForOpenUnits,
    };

    console.log("dadajlkjsad to send", dataToSend);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/cancel_units_subscriptions_by_units_ids_and_student_id`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage("تم إلغاء اشتراك الوحدات بنجاح");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
        refetchStudentUnits();
        setSelectedUnitsForCancel([]);
        setShowCancelWarning(false);
      });
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"></th>
              <th className="text-center align-middle"> الاسم</th>
              <th className="text-center align-middle"> اسم المستخدم</th>
              <th className="text-center align-middle"> النقاط</th>
              {/* <th className='text-center align-middle'>المدرسة</th>  */}
              {/* <th className='text-center align-middle'> الصف</th>  */}
              <th className="text-center align-middle">
                {" "}
                القسم الذي ينتمي إليه الطالب
              </th>
              {/* <th className='text-center align-middle'> الايميل</th>  */}

              {/* <th className='text-center align-middle'> حالة الحساب</th>  */}
              {/* <th className='text-center align-middle'> تاريخ الإنشاء</th>  */}

              <th className="text-center align-middle"> العمليات المتاحة</th>
              {/* <th className='text-center align-middle'> رقم هاتف الطالب</th>  */}
              {/* <th className='text-center align-middle'> رقم هاتف ولي الأمر</th>  */}
            </tr>
          </thead>

          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={12}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : (
              currentTeachers &&
              currentTeachers.map((student, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">
                      <input
                        type={"checkbox"}
                        checked={selectedStudents.some(
                          (st) => st.student_id == student.student_id
                        )}
                        onChange={(event) =>
                          handleAddDeleteStudent(event, student)
                        }
                        className="w-2"
                      />
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center align-middle"
                    >
                      {student.name}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center align-middle"
                    >
                      {student.username}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center align-middle"
                    >
                      {student.points}
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.school}</td>                        */}
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.class}</td>                        */}
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center align-middle"
                    >
                      {student.section_name}
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.email}</td>                        */}

                    {/* <td  className='text-center'>{student.phone_number}</td>    */}
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center align-middle activatOrNot'>
                            {student.is_active == 0 ? <img src={wrong} /> : <img src={right} />}
                        </td>    */}
                    {/* <td className='text-center align-middle activatOrNot'>
                            {student.created_at}
                        </td>    */}

                    <td className="text-center align-middle text-success fs-3">
                      <div
                        className="d-flex flex-column"
                        style={{ gap: "8px", marginTop: "5px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => {
                            navigate(`/callsRecord/${student.student_id}`);
                          }}
                        >
                          سجل المكالمات
                        </button>
                      </div>

                      {/* <button type="button" className="btn btn-primary" onClick={() => { } } >أرسل رسالة</button> */}

                      <div
                        className="d-flex flex-column"
                        style={{ gap: "8px", marginTop: "5px" }}
                      >
                        <button
                          className="btn btn-success shadow"
                          disabled={Isloading}
                          onClick={(e) => handleLoginAsStudent(e, student)}
                        >
                          تسجيل الدخول بحساب الطالب
                        </button>

                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedStudentForOpenUnits(student.student_id);
                            setSelectedUnitsForCancel([]);
                          }}
                        >
                          الوحدات المسجلة
                        </button>
                      </div>
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.phone_number}</td>                        */}
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.gaurdian_number}</td>     */}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div
        class="modal modal-fullscreen fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="exampleModalLabel">
                اشتراكات الوحدات للطالب
              </h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">
              <UnitsForStudentTable
                data={dataUnitsForStudent}
                isLoading={isLoadingUnits}
                selectedUnitsForCancel={selectedUnitsForCancel}
                setSelectedUnitsForCancel={setSelectedUnitsForCancel}
              />

              {selectedUnitsForCancel?.length > 0 && (
                <div className="my-2 flex flex-wrap">
                  {selectedUnitsForCancel.map((unit, idx) => {
                    return (
                      <div className="selectedStudent  border rounded py-2 px-3 d-flex align-items-center flex-direction-row gap-2">
                        <img
                          src={CloseIcon}
                          width={20}
                          height={20}
                          className="closeIcon"
                          onClick={() => deleteUnitFromArray(unit)}
                        />
                        <h6 className="mb-0">
                          {" "}
                          {unit.subject_name} / {unit.name}
                        </h6>
                      </div>
                    );
                  })}
                  <button
                    type="button"
                    class="btn btn-primary my-3"
                    onClick={() => setShowCancelWarning(true)}
                  >
                    إلغاء الاشتراك بالوحدات
                  </button>
                </div>
              )}

              {showCancelWarning && (
                <div className="my-2 text-center flex flex-column gap-3">
                  <h5 className="text-danger border p-2 shadow alert alert-danger">
                    عند قيامك بإلغاء الاشتراك للوحدات المطلوبة سيتم حذف جميع
                    تحصيل الطالب ضمن هذه الوحدات من دروس واختبارات سواء كانت
                    مؤتمتة أو تقليدية بشكل نهائي
                  </h5>

                  <button
                    className="btn btn-danger"
                    onClick={() => handleCancelUnitsForStudent()}
                  >
                    تأكيد عملية الإلغاء
                    {Isloading && (
                      <span className="d-inline-block mx-1">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Loading...</span>
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import "./styleTable.css";

export default function FaqsSectionsTable({ data, isLoading, refetch }) {
  const [currentSections, setCurrentSections] = useState([]);
  const [selectedteacherId, setSelectedTeacherId] = useState();
  const modalRef = useRef(null);
  const modalEditRef = useRef(null);
  const closeModalBtn = useRef();
  const closeModalEditBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const {
    reset,
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      id: "",
    },
  });

  useEffect(() => {
    setCurrentSections(data);
  }, [data]);

  const handleDeleteTeacher = (e, id) => {
    e.stopPropagation();

    setIsLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/faqs-sections/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        console.log(data);

        if (data.status == 200) {
          setSuccessMessage("تمت  عملية الحذف بنجاح");

          // modalRef.current.style.display = 'none';
          // closeModalBtn?.current?.click();

          setIsLoading(false);

          refetch();
        }
      })
      .catch((error) => {
        setErrorMessage("حدث خطأ ما, يرجى المحاولة لاحقا");
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        modalRef.current.style.display = "none";
        closeModalBtn?.current?.click();
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const dataToSend = {
      name: data.name,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/faqs-sections/${getValues("id")}`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log(data);

        if (data.status == 200) {
          setSuccessMessage("تمت  عملية التعديل بنجاح");
          refetch();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // modalRef.current.style.display = 'none';
        // closeModalBtn.current.click();

        setErrorMessage("حدث خطأ ما, يرجى المحاولة لاحقا");
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        closeModalEditBtn.current.style.display = "none";
        closeModalEditBtn?.current?.click();
      });
  };

  const selectedTeacherId = (id) => {
    console.log("id", id);
    setSelectedTeacherId(id);
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> اسم القسم</th>
              <th className="text-center align-middle">العمليات المتاحة</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={4}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : currentSections && currentSections.length > 0 ? (
              currentSections.map((section, index) => {
                return (
                  <tr
                    key={index}
                    // onClick={(() => {navigate(`/librarySection/${section.id}`)})}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">{section.name}</td>
                    <td className="text-center align-middle">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => {
                          e.stopPropagation();
                          selectedTeacherId(section.id);
                        }}
                      >
                        <div className="px-3">حذف</div>
                      </button>

                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalEdit"
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setValue("name", section.name);
                          setValue("id", section.id);
                        }}
                      >
                        <div className="px-3">تعديل</div>
                      </button>

                      <Link
                        onClick={(e) => e.stopPropagation()}
                        to={`/faqsQuestions/${section.id}`}
                        state={{ sectionName: section.name }}
                        className="btn btn-success"
                      >
                        <div className="px-3">

                        عرض الأسئلة
                        </div>
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="text-center w-100">
                <h3>لا يوجد اقسام بعد </h3>{" "}
              </div>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        ref={modalRef}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري الحذف ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>

            {
              <div className="modal-body">
                {isLoading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            }

            {
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss={Isloading ? "" : "exampleModal"}
                  onClick={(e) => handleDeleteTeacher(e, selectedteacherId)}
                >
                  حذف
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="modal fade"
        ref={closeModalEditBtn}
        id="exampleModalEdit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري التعديل ..." : "تعديل معلومات القسم"}
              </h5>
            </div>

            {
              <div className="modal-body">
                <label for="exampleFormControlInput1" class="form-label">
                  اسم القسم:
                </label>

                <input
                  {...register("name", { required: true })}
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="أدخل اسم القسم هنا ..."
                />
                {errors.name && (
                  <div className="text-danger">
                    يرجى إدخال اسم القسم ومن ثم اضافته
                  </div>
                )}
              </div>
            }

            {
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalEditBtn}
                >
                  تجاهل
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss={Isloading ? "" : "exampleModal"}
                  // onClick={(e) => handleEditSection(e)}
                >
                  تعديل
                </button>
              </div>
            }
          </div>
        </div>
      </form>
    </>
  );
}

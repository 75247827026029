// subjects for subscribtionsss

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";

import right from "../../assets/students/right.png";
import wrong from "../../assets/students/wrong.jpg";

function ExamStudentsTable({ data, isLoading, page, setStatus, setPage }) {
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [minusPage, setMinusPage] = useState(0);

  const [currentAdimis, setCurrentAdmins] = useState([]);
  const modalRef = useRef(null);
  const closeModalBtn = useRef(null);
  const [Isloading, setIsLoading] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const renderRef = useRef(0);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("data from react query", data);
    setDataSubject(data);
  }, [data]);

  renderRef.current++;
  console.log("render for the ", renderRef.current, " times ");

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
      {/* <div className='text-center my-5'>
           <Link to='/addSections'>
             <button className='btn btn-primary '>إضافة قسم</button>
           </Link>
        </div> */}

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "400px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold">
              <th className="text-center text-white"> الاسم</th>
              <th className="text-center text-white"> اسم المستخدم</th>
              <th className="text-center text-white"> النقاط</th>
              {/* <th className='text-center text-white'>المدرسة</th>  */}
              {/* <th className='text-center text-white'> الصف</th>  */}
              <th className="text-center text-white">
                {" "}
                القسم الذي ينتمي إليه الطالب
              </th>
              {/* <th className='text-center text-white'> الايميل</th>  */}

              {/* <th className='text-center text-white'> حالة الحساب</th>  */}
              <th className="text-center text-white"> تاريخ الإنشاء</th>

              {/* <th className='text-center text-white'>  العمليات المتاحة</th>  */}
              <th className="text-center text-white"> رقم هاتف الطالب</th>
              {/* <th className='text-center text-white'> رقم هاتف ولي الأمر</th>  */}
              <th className="text-center text-white"> العمليات المتاحة</th>
            </tr>
          </thead>

          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <tr className="">
                <td
                  colSpan={7}
                  className="text-center align-middle text-center"
                >
                  <div className=" text-center">
                    <div class="spinner-border" role="status">
                      <div class="visually-hidden">Loading...</div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              dataSubject &&
              dataSubject?.data?.map((student, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center"
                    >
                      {student.name}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center"
                    >
                      {student.username}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center"
                    >
                      {student.points}
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.school}</td>                        */}
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.class}</td>                        */}
                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center"
                    >
                      {student.section_name}
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.email}</td>                        */}

                    {/* <td  className='text-center'>{student.phone_number}</td>    */}
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center activatOrNot'>
                            {student.is_active == 0 ? <img src={wrong} /> : <img src={right} />}
                        </td>    */}
                    <td className="text-center activatOrNot">
                      {student.created_at}
                    </td>

                    <td
                      onClick={() => {
                        navigate(`/student/${student.student_id}`);
                      }}
                      className="text-center"
                    >
                      {student.phone_number}
                    </td>
                    {/* <td onClick={(() => {navigate(`/student/${student.student_id}`)})}  className='text-center'>{student.gaurdian_number}</td>     */}

                    <td className="text-center">
                      <button
                        disabled={!student.passed_exam}
                        className={`btn ${
                          student.passed_exam ? "btn-success" : "btn-dark"
                        } `}
                        style={{ zIndex: 999 }}
                        to={`/showExamQa/${student.student_id}/${id}`}
                        state={{ examId: id }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/showExamQa/${student.student_id}/${id}`);
                        }}
                      >
                        أجوبة الطالب
                      </button>
                      <br/>
                      <button
                        disabled={!student.passed_exam}
                        className={`btn ${
                          student.passed_exam ? "btn-primary " : "btn-dark mx-1"
                        }`}
                        to={``}
                        state={{ examId: id }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `https://backend.myway.edu.sy/api/print_student_traditional_exam_answers_by_id?student_id=${student.student_id}&te=${id}`
                          );
                        }}
                      >
                        {" "}
                        طباعة الأجوبة 
                      </button>
                    </td>
                  </tr>
                );
              })
            )}

            {dataSubject && dataSubject?.data?.length == 0 && (
              <tr>
                <td colSpan={7} className="text-center align-middle">
                  <h2 className=""> لا يوجد نتائج مطابقة لعملية البحث</h2>{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default React.memo(ExamStudentsTable);

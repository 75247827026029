import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styleTable.css";
import right from "../../assets/students/right.png";
import wrong from "../../assets/students/wrong.jpg";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";

export default function AllUnitsTable({
  data,
  isLoading,
  selectedUnits,
  setSelectedunits,
}) {
  const navigate = useNavigate();
  const [currentTeachers, setCurrentTeachers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [selectedStudentId, setSelectedStudent] = useState();
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [isAllUnits, setIsAllUnits] = useState(false);

  useEffect(() => {
    console.log("data from react query", data?.data);
    setCurrentTeachers(data?.data);
  }, [data]);

  const handleselectStudent = (event, studentId) => {
    event.stopPropagation();
    setSelectedStudent(studentId);
  };

  const handleAddDeleteStudent = (event, clickedStudent) => {
    // console.log(event.target.checked , clickedStudent);

    // get the previous array values and store it
    const Currentstudents = selectedUnits;
    // console.log('current students ' , Currentstudents);
    // check if the student is exist in the selected students array
    const isExist = selectedUnits.some(
      (student) => student.unit_id == clickedStudent.unit_id
    );
    // console.log('is exist', isExist);
    // check if the student is selected or not
    const isSelected = event.target.checked;
    // console.log('is selected' , isSelected);

    // if it is not exist and is checked push it to the array
    if (!isExist && isSelected) {
      console.log("adding the student");
      console.log("array added the studdent", [
        ...Currentstudents,
        { ...clickedStudent },
      ]);
      setSelectedunits([...Currentstudents, { ...clickedStudent }]);
    }

    // if the student exists and is not selected then remove it from the array
    if (isExist && !isSelected) {
      const newCurrentStudent = Currentstudents.filter(
        (student) => student.unit_id != clickedStudent.unit_id
      );
      setSelectedunits([...newCurrentStudent]);
    }
  };

  const handleSelectAllUnits = () => {
    setIsAllUnits((prev) => !prev);
  };

  useEffect(() => {
    let currentSelectedUnits = [];

    if (isAllUnits) {
      currentSelectedUnits = currentTeachers.map((unit, idx) => unit);
      setSelectedunits([...selectedUnits, ...currentSelectedUnits]);
      console.log("current selected units", currentSelectedUnits);
    } else {
      let allCurrentUnits = [...currentTeachers];
      currentSelectedUnits = [...selectedUnits];

      let newSelectedUnits = [];

      function checkIfUnitIsExist(selectedUnit, currentTableUnits) {
        const selectedId = selectedUnit.unit_id;

        // check if the selected_id is exiest in the current units of current subject
        for (let i = 0; i < currentTableUnits.length; i++) {
          if (currentTableUnits[i].unit_id == selectedId) {
            return true;
          }
        }

        return false;
      }

      for (let i = 0; i < selectedUnits.length; i++) {
        if (checkIfUnitIsExist(selectedUnits[i], currentTeachers)) {
        } else {
          newSelectedUnits.push({ ...selectedUnits[i] });
        }
      }

      setSelectedunits([...newSelectedUnits]);
    }
  }, [isAllUnits]);

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit w-50  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle">
                <input
                  type={"checkbox"}
                  // value={isAllUnits}
                  onChange={handleSelectAllUnits}
                />
              </th>
              <th className="text-center align-middle"> اسم الوحدة</th>
            </tr>
          </thead>

          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={12}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : (
              currentTeachers &&
              currentTeachers.map((student, index) => {
                {
                  /* console.log('uninininin', student.unit_id) */
                }
                return (
                  <tr
                    key={index}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">
                      <input
                        type={"checkbox"}
                        checked={selectedUnits.some(
                          (st) => st.unit_id == student.unit_id
                        )}
                        onChange={(event) =>
                          handleAddDeleteStudent(event, student)
                        }
                        className="w-2"
                      />
                    </td>
                    <td className="text-center align-middle">
                      {student.unit_name}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import "./styleTable.css";

export default function LibrarySectionsTable({ data, isLoading }) {
  const navigate = useNavigate();
  const [currentSections, setCurrentSections] = useState([]);
  const [selectedteacherId, setSelectedTeacherId] = useState();
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log("data from react query", data?.data);
    setCurrentSections(data);
  }, [data]);

  const handleDeleteTeacher = (e, id) => {
    e.stopPropagation();
    // console.log("id guardian to delete is => " , id);

    setIsLoading(true);
    // modalRef.current.style.display = 'none';
    // closeModalBtn.current.click();

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_website_library_section_by_website_library_section_id/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((data) => {
        console.log(data);

        if (data.status == 200) {
          setSuccessMessage("تمت  عملية الحذف بنجاح");

          // modalRef.current.style.display = 'none';
          // closeModalBtn?.current?.click();

          setIsLoading(false);

          const newSections = currentSections.filter(
            (currentSection) => currentSection.id != id
          );

          setCurrentSections([...newSections]);
        }
      })
      .catch((error) => {
        // modalRef.current.style.display = 'none';
        // closeModalBtn.current.click();

        setErrorMessage("حدث خطأ ما, يرجى المحاولة لاحقا");
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        modalRef.current.style.display = "none";
        closeModalBtn?.current?.click();
      });
  };

  const handleEditLibrarySection = (e, id) => {
    e.stopPropagation();

    navigate(`/editlibrarysection/${id}`);
  };

  const selectedTeacherId = (id) => {
    console.log("id", id);
    setSelectedTeacherId(id);
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> اسم القسم</th>
              <th className="text-center align-middle"> السعر</th>
              <th className="text-center align-middle"> مجاني</th>
              {/* <th className='text-center text-white'> رقم الهاتف</th>  */}
              <th className="text-center align-middle">العمليات المتاحة</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={4}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : currentSections && currentSections.length > 0 ? (
              currentSections.map((section, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(`/librarySection/${section.id}`);
                    }}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">{section.name}</td>
                    <td className="text-center align-middle">
                      {section.price}
                    </td>
                    <td className="text-center align-middle">
                      {section.is_free == 1 ? "نعم" : "لا"}
                    </td>
                    {/* <td  className='text-center'>{section.phone_number}</td>    */}
                    <td className="text-center align-middle">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => {
                          e.stopPropagation();
                          selectedTeacherId(section.id);
                        }}
                      >
                        <div className="px-3">حذف</div>
                      </button>

                      <button
                        onClick={(e) => handleEditLibrarySection(e, section.id)}
                        className="btn btn-primary"
                      >
                        <div className="px-3">تعديل</div>
                      </button>

                      <Link
                        onClick={(e) => e.stopPropagation()}
                        to={`/librarySectionStudents/${section.id}`}
                        state={{ sectionName: section.name }}
                        className="btn btn-success"
                      >
                        <div className="px-3">الطلاب</div>
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="text-center w-100">
                <h3>لا يوجد اقسام لمكتبة الموقع</h3>{" "}
              </div>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        ref={modalRef}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري الحذف ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>

            {Isloading && (
              <div className="modal-body">
                {Isloading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {!Isloading && (
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss={Isloading ? "" : "exampleModal"}
                  onClick={(e) => handleDeleteTeacher(e, selectedteacherId)}
                >
                  حذف
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

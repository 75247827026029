import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useRef } from 'react';
import { useUploadForm } from '../../customhook/Uploadfiles';
export default function Create_unit_automated_quiz_Logic({ isAboveLevel, isFinal }) {


  const { getValues, register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
    mode: "onChange", defaultValues: {
      QuizeName: '',
      points: 0,
      descriptionQuize: '',
      duration: '',
      quizType: 'final',
      file: null,
    }
  });
  const questionText = useRef(null);
  const { UnitId } = useParams();

  const { isSuccess: create_unit_automated_quiz_form_success,
    uploadForm: create_unit_automated_quiz_func,
    progress: create_unit_automated_quiz_form_progress,
    IsError: create_unit_automated_quiz_form_error,
    data: create_unit_automated_quiz_form_data,
    IsLoading: create_unit_automated_quiz_form_Loading } =
    useUploadForm('/create_automated_quiz');

  const onSubmit = async (data) => {

    const create_unit_automated_quiz_form = new FormData();
    // create_unit_automated_quiz_form.append('unit_id',UnitId);
    create_unit_automated_quiz_form.append('isFinal', data.quizType == 'final' ? 1 : 0);
    create_unit_automated_quiz_form.append('duration', data.duration);
    create_unit_automated_quiz_form.append('isAboveLevel', data.quizType == 'above_level' ? 1 : 0);
    create_unit_automated_quiz_form.append('nameOfQuiz', data.QuizeName);
    create_unit_automated_quiz_form.append('points', data.points);
    create_unit_automated_quiz_form.append('description', data.descriptionQuize)
    create_unit_automated_quiz_form.append('transable', 0)
    create_unit_automated_quiz_form.append('is_bank_quiz', 1)
    if (data?.file?.length > 0)
      create_unit_automated_quiz_form.append('file', data?.file[0])

    if (questionText.current != '') {
      questionText.current && create_unit_automated_quiz_form.append('automated_quiz_text', questionText.current)
    }


    await create_unit_automated_quiz_func(create_unit_automated_quiz_form);
  };









  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    create_unit_automated_quiz_form_error,
    create_unit_automated_quiz_form_progress,
    create_unit_automated_quiz_form_success,
    create_unit_automated_quiz_form_Loading,
    onSubmit,
    questionText
  }
}

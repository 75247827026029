import { useLocation, useNavigate } from "react-router-dom";

export const useFilter = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(search);
  
    const handleParamsClick = (
      name,
      value
    ) => {
      if (Array.isArray(value)) {
        if (value.length > 0) searchParams.set(name, value.join(","));
        else searchParams.delete(name);
      } else if (value) {
        searchParams.set(name, value.toString());
      }
      const newUrl = `${pathname}?${searchParams.toString()}`;
      navigate(newUrl);
    };
  
    const handleParamDelete = (name) => {
      searchParams.delete(name);
      const newUrl = `${pathname}?${searchParams.toString()}`;
      navigate(newUrl);
    };
  
    const handleParamsDeleteAll = () => {
      const newUrl = `${pathname}`;
      navigate(newUrl);
    };
  
    const handlePageClick = (page) => {
      handleParamsClick("page", page);
    };
  
    return {
      searchParams,
      handlePageClick,
      handleParamsClick,
      handleParamDelete,
      handleParamsDeleteAll,
    };
  };
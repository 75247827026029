import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ConfirmRequest from "../../components/ConfirmRequest/ConfirmRequest";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import fetchTeachers from "../../redux-toolkit/api/fetchTeachers";
import TaachersTable from "../../components/ConfirmRequest/TeachersTable";
import "./Teachers.css";
import { NavLink } from "react-router-dom";
import group from "../../assets/AboutUs/Group 408.png";

const Teachers = (props) => {
  const [page, setPage] = useState(
    +localStorage.getItem("teachers_pag") == null
      ? 1
      : Number(+localStorage.getItem("teachers_pag"))
  );
  const { isLoading, isError, data, error } = useQuery(
    ["payments", page],
    fetchTeachers
  );

  function handlePageClick(data) {
    localStorage.setItem("teachers_pag", data.selected);
    setPage(data.selected + 1);
  }

  return (
    <Layout>
      <div className="container all">
        <h1
          className="pt-0 mb-1 text-center pb-4 pt-3"
          style={
            {
              // borderBottom: '1px solid green'
            }
          }
        >
          الأساتذة
        </h1>

        <div className="d-flex mb-3 justify-content-center border w-auto dot p-2 flex-column align-items-center mt-5 pt-2 ">
          <NavLink to="/addteacher">
            <img src={group} className="me" />
          </NavLink>
          <h5 className="gre mt-2"> أضف أستاذ</h5>

        </div>

        <div className="d-flex justify-content-around mb-5">
          {<TaachersTable data={data?.data} isLoading={isLoading} />}
        </div>
        {data?.data.total < 1 ? (
          <> </>
        ) : (
          <Pagination
            data={data?.data}
            handlePageClick={handlePageClick}
            page={page}
          />
        )}
      </div>
    </Layout>
  );
};

export default Teachers;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ConfirmRequest from "../../components/ConfirmRequest/ConfirmRequest";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import fetchUserOpinions from "../../redux-toolkit/api/fetchUserOpinions";
import UserOpinionsTable from "../../components/ConfirmRequest/UserOpinionsTable";
// import './Teachers.css';
import { NavLink } from "react-router-dom";
import group from "../../assets/AboutUs/Group 408.png";

const UserOpinions = (props) => {
  const [page, setPage] = useState(
    +localStorage.getItem("useropinion_pag") == null
      ? 1
      : Number(+localStorage.getItem("useropinion_pag"))
  );
  const { isLoading, isError, data, error } = useQuery(
    ["payments", page],
    fetchUserOpinions
  );

  function handlePageClick(data) {
    localStorage.setItem("useropinion_pag", data.selected);
    setPage(data.selected + 1);
  }

  return (
    <Layout>
      <div className="container all">
        <h1
          className="pt-0 mb-1 pb-4 pt-3"
          style={
            {
              // borderBottom: '1px solid green'
            }
          }
        >
          اراء الطلاب
        </h1>

        {/* <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
              <h5 className='gre'> أضف أستاذ</h5> 
              <NavLink to='/addteacher'>
              <img src={group} className="me" />
              </NavLink>
        </div> */}

        <div className="d-flex justify-content-around mb-5">
          {<UserOpinionsTable data={data?.data} isLoading={isLoading} />}
        </div>
        {data?.data.total < 1 ? (
          <> </>
        ) : (
          <Pagination
            data={data?.data}
            handlePageClick={handlePageClick}
            page={page}
          />
        )}
      </div>
    </Layout>
  );
};

export default UserOpinions;

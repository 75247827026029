import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExamsTable from "../../components/ConfirmRequest/ExamsTable";
import TableSections from "../../components/ConfirmRequest/TableSections";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import ExamsLogic from "./ExamsLogic";
import { NavLink, useLocation, useParams } from "react-router-dom";
import group from "../../assets/AboutUs/Group 408.png";

const ShowExams = () => {
  //   console.log("YESSSSSSSSSS");
  const {
    listRef,
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
  } = ExamsLogic();

  const dataSections = data?.data;
  const [status, setStatus] = useState(0);
  const { sectionId, subjectId } = useLocation().state;

  return (
    <>
      <Layout>
        <div className="container all">
          <h1 className="pt-0 mb-4 pb-4 pt-3">المذاكرات</h1>

          <div className="d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 ">
            <h5 className="gre"> أضف مذاكرة</h5>
            <NavLink to={`/addTraditionalExam/${sectionId}`}>
              <img src={group} className="me" />
            </NavLink>
          </div>

          <div className="d-flex justify-content-around"></div>

          {
            <ExamsTable
              data={dataSections}
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              setStatus={setStatus}
            />
          }

          {/* {data?.total < 10  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
        </div>
      </Layout>
    </>
  );
};

export default ShowExams;

// subjects for subscribtionsss

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
export default function SupderiorsSections({
  data,
  isLoading,
  page,
  setStatus,
  setPage,
  refetch,
}) {
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [minusPage, setMinusPage] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedID, setSelectedID] = useState();
  const [name, setName] = useState("");
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handleDeleteSections = (id) => {
    const temp = [...dataSubject];
    const DataDelte = temp.filter((item) => item.section_id !== id);

    setDataSubject(DataDelte);
    if (DataDelte.length === 0 && page > 1) {
      setPage(page - 1);
      setMinusPage(0);
      setStatus(1);
    }
    setFlag(1);
  };

  useEffect(() => {
    setDataSubject(data);
  }, [data]);

  async function deletePost(id) {
    const res = axios
      .delete(`${process.env.REACT_APP_API_URL}/superiors-sections/${id}`, {
        headers: {
          "content-type": "text/json",
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          handleDeleteSections(id);
          setSuccessMessage("تمت عملية الحذف بنجاح");
          refetch();
          return res;
        } else {
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  }
  // setStatus('Delete successful');
  const [idDelete, setIdDelete] = useState();
  const handleDelete = (id) => {
    // DELETE request using fetch with async/await

    setIdDelete(id);
    // deletePost(id);
    // handleDeleteSections(id);
  };

  const handleDeleteItem = () => {
    deletePost(idDelete);
  };

  const handleEditSection = () => {
    setLoadingEdit(true);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/superiors-sections/${selectedID}`,
        { name },
        {
          headers: {
            "content-type": "text/json",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Content-Encoding": "gzip , deflate, br",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          setSuccessMessage("تمت عملية التعديل بنجاح");
          refetch();
          return res;
        } else {
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
      <div className="text-center my-5">
        <Link to="/addSuperiorsSection">
          <button className="btn btn-primary ">إضافة قسم</button>
        </Link>
      </div>

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold">
              <th className="text-center align-middle text-dark fw-bold"> القسم </th>
              <th className="text-center align-middle text-dark fw-bold"> العمليات</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={2}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : dataSubject ? (
              dataSubject.length > 0 &&
              dataSubject?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">{item?.name}</td>
                    <td className="text-center align-middle">
                      {/* <i className='fa fa-check yes fa-2x'> </i>  */}

                      <button
                        onClick={() => {
                          setSelectedID(item.id);
                          setName(item.name);
                        }}
                        className="ms-3 btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#editmodal"
                      >
                        تعديل
                      </button>
                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="ms-3 btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        حذف
                      </button>
                      <Link
                        to={`/superiorslist/${item.id}`}
                        className="ms-3 btn btn-primary"
                      >
                        عرض
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={2} className="text-center align-middle">
                <h3>لايوجد اقسام</h3>{" "}
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">هل انت متاكد انك تريد الحذف</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteItem}
              >
                نعم
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="shadow w-50 py-2 border-0"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                إلغاء
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleEditSection}
              >
                تأكيد{" "}
                {loadingEdit && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

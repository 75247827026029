import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchSectionsApi from "../../redux-toolkit/api/fetchSections";

const ShowSectionsLogic = () => {
  const [page, setPage] = useState(
    +localStorage.getItem("sections_pag") == null
      ? 1
      : Number(+localStorage.getItem("sections_pag"))
  );

  const { isLoading, isError, data, error } = useQuery(
    ["sections", page],
    fetchSectionsApi
  );

  function handlePageClick(data) {
    localStorage.setItem("sections_pag", data.selected);
    setPage(data.selected + 1);
  }

  return {
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
  };
};

export default ShowSectionsLogic;

import React, { useState } from "react";
import { useQuery } from "react-query";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import { NavLink } from "react-router-dom";
import group from "../../assets/AboutUs/Group 408.png";
import FaqsSectionsTable from "../../components/ConfirmRequest/FaqsSectionsTable";
import fetchFaqsSections from "../../redux-toolkit/api/fetchFaqsSections";
import { useRef } from "react";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import axios from "axios";
import { useForm } from "react-hook-form";

const FAQS = (props) => {
  const [page, setPage] = useState(
    +localStorage.getItem("faqs_pag") == null
      ? 1
      : Number(+localStorage.getItem("library_pag"))
  );
  const modalRef = useRef(null);
  const closeModalBtn = useRef();
  const [Isloading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["library", page],
    fetchFaqsSections
  );

  function handlePageClick(data) {
    localStorage.setItem("library_pag", data.selected);
    setPage(data.selected + 1);
  }

  const {
    reset,
    getValues,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const dataToSend = {
      name: data.name,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/faqs-sections`, dataToSend, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        if (data.status == 200) {
          refetch();
          setSuccessMessage("تمت  عملية الإضافة بنجاح");
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        setErrorMessage("حدث خطأ ما, يرجى المحاولة لاحقا");
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        modalRef.current.style.display = "none";
        closeModalBtn?.current?.click();
      });
  };

  return (
    <Layout>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="container all">
        <h1
          className="pt-0 mb-1 pb-4 pt-3"
          
        >
          {" "}
          أقسام الأسئلة الشائعة
        </h1>
        <div className="d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 ">
          <h5 className="gre">أضف قسم</h5>
          <button
            type="button"
            className="btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModaladd"
          >
            <img src={group} className="me" />
          </button>
        </div>
        <div className="d-flex justify-content-around mb-1">
          {
            <FaqsSectionsTable
              data={data?.data?.data}
              isLoading={isLoading}
              refetch={refetch}
            />
          }
        </div>
        {data?.total < 2 ? (
          <> </>
        ) : (
          <Pagination
            data={data?.data}
            handlePageClick={handlePageClick}
            page={page}
          />
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="modal fade"
        ref={modalRef}
        id="exampleModaladd"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري الإضافة ..." : "إضافة قسم"}
              </h5>
            </div>

            <div className="modal-body">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  اسم القسم:
                </label>

                <input
                  {...register("name", { required: true })}
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="أدخل اسم القسم هنا ..."
                />
                {errors.name && (
                  <div className="text-danger">
                    يرجى إدخال اسم القسم ومن ثم اضافته
                  </div>
                )}
              </div>
            </div>

            {
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  disabled={watch("name").length == 0}
                  type="submit"
                  className="btn btn-success"
                  data-bs-dismiss={Isloading ? "" : "exampleModal"}
                  // onClick={(e) => handleAddSection()}
                >
                  إضافة القسم
                </button>
              </div>
            }
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default FAQS;

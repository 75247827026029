// subjects for subscribtionsss

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
export default function OffersTable({
  data,
  isLoading,
  page,
  setStatus,
  setPage,
}) {
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [minusPage, setMinusPage] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //  if(dataSubject.length===0 && flag===1){
  //     window.location.reload();
  // }

  const handleDeleteSections = (id) => {
    console.log("idddddd subject", id);
    const temp = [...dataSubject];
    const DataDelte = temp.filter((item) => item.offer_id !== id);
    console.log("Data Deleteeed", DataDelte);
    setDataSubject(DataDelte);
    if (DataDelte.length === 0 && page > 1) {
      setPage(page - 1);
      setMinusPage(0);
      setStatus(1);
    }
    setFlag(1);
  };

  useEffect(() => {
    console.log("data from react query", data);
    setDataSubject(data);
  }, [data]);

  async function deletePost(id) {
    console.log("iddddd", id);
    const res = axios
      .delete(`${process.env.REACT_APP_API_URL}/delete_offer/${id}`, {
        headers: {
          "content-type": "text/json",
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          console.log("data delete Subscribtion", data);
          handleDeleteSections(id);
          setSuccessMessage("تمت عملية الحذف بنجاح");
          return res;
        } else {
          console.log(res);
          setErrorMessage("غير متصل بالشبكة");
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setErrorMessage(error.response.data.message);
      });
  }
  // setStatus('Delete successful');
  const [idDelete, setIdDelete] = useState();
  const handleDelete = (id) => {
    // DELETE request using fetch with async/await
    console.log("no id", id);
    setIdDelete(id);
    // deletePost(id);
    // handleDeleteSections(id);
  };

  const handleDeleteItem = () => {
    deletePost(idDelete);
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit w-75 table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> العروض</th>
              <th className="text-center align-middle"> العمليات</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={2}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : dataSubject && dataSubject.length > 0 ? (
              dataSubject?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.offer_text}
                    </td>
                    <td className="text-center align-middle">
                      {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                      {/* <Link to={`/showSubSections/${item?.offer_id}`} state={{sectionId: item?.section_id}} className="ms-3 btn btn-primary">عرض</Link> */}
                      <Link
                        to={`/addOffers/${item?.offer_id}`}
                        state={{ sectionId: item?.offer_id }}
                        className="ms-3 btn btn-success"
                      >
                        <div className="px-3">تعديل</div>
                      </Link>
                      <button
                        onClick={() => handleDelete(item?.offer_id)}
                        className="ms-3 btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <div className="px-3">حذف</div>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={2}>
                <h3 className="text-center my-5">لم يتم إضافة عروض بعد</h3>
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div className="text-center my-5">
        <Link to="/addOffers">
          <button className="btn btn-primary ">إضافة عرض</button>
        </Link>
      </div>

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Launch static backdrop modal
</button> */}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">هل انت متاكد انك تريد الحذف</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteItem}
              >
                نعم
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

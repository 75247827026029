import { CiSearch } from "react-icons/ci";
import { useState } from "react";

import { BiSearch } from "react-icons/bi";
import { CgSearchFound } from "react-icons/cg";
import { BsSearch } from "react-icons/bs";
import { useFilter } from "../../hooks";




function Search({ className, name, placeholder,type }) {
  const { handleParamsClick , handleParamsDeleteAll} = useFilter();
  const [typingTimeout, setTypingTimeout] =useState();

  const handleInputChange = (e) => {
    const { value } = e.target;
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      if (value.trim() === "") {
       
        handleParamsDeleteAll()
      } else {
       
        handleParamsClick(name ? `${name}` : "search", value);
      }
      // if (name) {
      //   handleParamsClick("sort_by", name);
      // }
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };

  return (
    <div
      className={`bg-[#F6F8F9]  w-full max-md:w-[150px]  lg:mx-3 max-md:mx-auto    flex items-center justify-between space-x-2 p-2 rounded-xl ${className}`}
    >
      <span>
        <BsSearch size={15} className="h-full mx-1 " />
      </span>
      <input
        type={type ? type : "search"}
        placeholder={placeholder ? placeholder : "إبحث"}
        onChange={handleInputChange}
        className="w-100  bg-[#F6F8F9] input_placeHolder"
      />
    </div>
  );
}

export default Search;

//just subjectsssss

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export default function CommentsSubjects({ data, isLoading, subjectId }) {
  const [dataSubject, setDataSubject] = useState(data);
  const [flag, setFlag] = useState(0);
  const [itemDelete, setItem] = useState();
  const [idSubjectDelete, setIdSubject] = useState();

  const navigate = useNavigate();

  const handleDeleteSubject = (commentId, subjectId) => {
    console.log("idddddd subject", commentId);
    const temp = [...dataSubject];
    const DataDelte = temp.filter((item) => item.id !== commentId.id);
    console.log("Data Deleteeed", DataDelte);
    setDataSubject(DataDelte);
    setFlag(1);
  };

  useEffect(() => {
    setDataSubject(data);
  }, [data]);

  async function deletePost(item, subjectId) {
    const res = axios
      .delete(`${process.env.REACT_APP_API_URL}/comments/${item.id}`, {
        headers: {
          "content-type": "text/json",
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        // data:{
        //   "comment":item.comment,
        //   "subject_id":subjectId,
        //   "student_id":1,

        // }
      })
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;
          console.log("data delete Subscribtion", data);
          handleDeleteSubject(item, subjectId);
          alert("تم الحذف بنجاح");
          window.location.reload();
          return res;
        } else {
          console.log(res);
          throw new Error("there is an error");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  // setStatus('Delete successful');
  const handleDelete = (item, subjectId) => {
    // DELETE request using fetch with async/await
    console.log("no id", item);
    setItem(item);
    setIdSubject(subjectId);
  };

  const handleDeleteItem = () => {
    deletePost(itemDelete, idSubjectDelete);
  };

  const AddComments = (id) => {
    navigate("/addComments", { state: { subjectid: id } });
  };

  const handleEdit = (item, id) => {
    navigate(`/addComments/${id}`, { state: { comment: item } });
  };

  const handleShow = (item) => {
    navigate("/showComment", { state: { comment: item } });
  };

  return (
    <>
      <div className="overflow-auto w-100">
        <div className="text-center my-1">
          <button
            className="btn btn-primary"
            onClick={() => AddComments(subjectId)}
          >
            إضافة تعليق
          </button>
        </div>

        <table
          className="table table-striped table-min-fit table-hover shadow rounded border w-75 mt-5"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold">
              <th className="text-center align-middle text-dark fw-bold w-50">
                {" "}
                التعليق{" "}
              </th>
              <th className="text-center align-middle text-dark fw-bold w-50">
                {" "}
                العمليات
              </th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={2}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : dataSubject && dataSubject.length > 0 ? (
              dataSubject?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.comment}{" "}
                    </td>
                    <td className="text-center align-middle d-flex gap-2 justify-content-center">
                      {/* <i className='fa fa-check yes fa-2x'> </i>  */}
                      <button
                        onClick={() => handleShow(item)}
                        className="ms-3 btn btn-primary"
                      >
                        <div className="px-3">عرض </div>
                      </button>
                      <button
                        onClick={() => handleEdit(item, subjectId)}
                        className="ms-3 btn btn-warning"
                      >
                        <div className="px-3">تعديل </div>
                      </button>
                      <button
                        onClick={() => handleDelete(item, subjectId)}
                        className="ms-3 btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <div className="px-3">حذف</div>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={2} className="text-center align-middle">
                {" "}
                <h3>لايوجد تعليقات</h3>
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div class="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">هل انت متاكد انك تريد الحذف</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                لا
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteItem}
              >
                نعم
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

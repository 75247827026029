import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExamsTable from "../../components/ConfirmRequest/ExamsTable";
import TableSections from "../../components/ConfirmRequest/TableSections";
import TableSectionsExams from "../../components/ConfirmRequest/TableSectionsExams";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import ExamsLogic from "./ExamsLogic";

const ShowSections = () => {
  const {
    listRef,
    isLoading,
    isError,
    data,
    error,
    handlePageClick,
    page,
    setPage,
  } = ExamsLogic();

  const dataSections = data?.data;
  const [status, setStatus] = useState(0);

  return (
    <>
      <Layout>
        <div className="container all">
          <h1
            className="pt-0 mb-4 pb-4 pt-3"
           
          >
            الأقسام
          </h1>
          <div className="d-flex justify-content-around"></div>

          {
            <TableSectionsExams
              data={dataSections}
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              setStatus={setStatus}
            />
          }

          {data?.total < 10 ? (
            <> </>
          ) : (
            <Pagination
              data={data}
              handlePageClick={handlePageClick}
              page={page}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default ShowSections;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToastSuccessError from "../ToastSuccessError/ToastSucessError";
import "./styleTable.css";

export default function ConfirmRequest({ data, isLoading }) {
  const navigate = useNavigate();
  const [currentAdimis, setCurrentAdmins] = useState([]);
  const modalRef = useRef(null);
  const closeModalBtn = useRef(null);
  const [Isloading, setIsLoading] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setCurrentAdmins(data?.data);
  }, [data]);

  const handleDeleteAdmin = (e, id) => {
    e.stopPropagation();
    setIsLoading(true);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/delete_admin_by_id/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(data);
          setIsLoading(false);

          setSuccessMessage("تمت  عملية الحذف بنجاح");
          // hide the confirm deletion modal
          modalRef.current.style.display = "none";
          closeModalBtn.current.click();

          const newCurrentAdmins = currentAdimis.filter(
            (currentAdmin) => currentAdmin.id != id
          );
          console.log("admin deleted successfully ");
          setCurrentAdmins([...newCurrentAdmins]);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setErrorMessage("حدث خطأ ما");
        modalRef.current.style.display = "none";
        closeModalBtn.current.click();
      });
  };

  const handleEditAdmin = (e, id) => {
    e.stopPropagation();
    navigate(`/editAdmin/${id}`);
  };

  const selectedAdminId = (id) => {
    console.log("id", id);
    setSelectedDeleteId(id);
  };

  return (
    <>
      <ToastSuccessError
        successMessage={successMessage}
        ErrorMessage={ErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <div className="overflow-auto w-100">
        <table
          className="table table-striped table-min-fit  table-hover shadow rounded border  mt-3"
          style={{ minHeight: "100px" }}
        >
          <thead>
            <tr className="align-middle table-head fw-bold text-white">
              <th className="text-center align-middle"> اسم الادمن</th>
              <th className="text-center align-middle"> اسم المستخدم </th>
              <th className="text-center align-middle"> الصلاحية</th>
              <th className="text-center align-middle">العمليات المتاحة</th>
            </tr>
          </thead>
          <tbody style={{ minHeight: "400px" }}>
            {isLoading ? (
              <td colSpan={3}>
                <div className="w-100 text-center my-5 d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            ) : currentAdimis && currentAdimis.length > 0 ? (
              currentAdimis.map((admin, index) => {
                return (
                  <tr
                    key={index}
                    // onClick={(() => {navigate(`/admin/${admin.id}`)})}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #1a1a1a1a",
                    }}
                    className="tableRowHover"
                  >
                    <td className="text-center align-middle">{admin.name}</td>
                    <td className="text-center align-middle">
                      {admin.username}
                    </td>
                    <td className="text-center align-middle">
                      {admin.role_name}
                    </td>
                    {/* <td  className='text-center'>{teacher.email}</td>                       
                        <td  className='text-center'>{teacher.phone_number}</td>    */}
                    <td className="text-center align-middle havebuttons">
                      {/* <button 
                            className="btn bg-danger m-1" 
                            >حذف</button> */}
                      {admin.role_id != 7 ? (
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => selectedAdminId(admin.id)}
                        >
                          <div className="px-3">حذف</div>
                        </button>
                      ) : null}

                      <button
                        className="btn bg-primary m-1"
                        onClick={(e) => handleEditAdmin(e, admin.id)}
                      >
                        <div className="px-3">تعديل</div>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={3} className="text-center w-100">
                <h3>لا يوجد حسابات ادمنز بعد</h3>{" "}
              </td>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        ref={modalRef}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                {Isloading ? "جاري الحذف ..." : "هل أنت متأكد ؟"}
              </h5>
            </div>
            {Isloading && (
              <div className="modal-body">
                {Isloading && (
                  <div className="mb-3 d-flex justify-content-center">
                    <div class="spinner-border mx-auto" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!Isloading && (
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={closeModalBtn}
                >
                  تجاهل
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => handleDeleteAdmin(e, selectedDeleteId)}
                >
                  حذف
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

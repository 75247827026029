import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import React, { useEffect, useState } from "react";
import "./components/Subject/SubjectImage.css";
import CallsRecord from "./pages/callsRecord/callsRecord";
import Exams from "./pages/Exams";
import ShowExams from "./pages/ShowExams/index";
import AddTraditionalExam from "./pages/AddTraditionalExam";
import ShowExamDetails from "./pages/ShowExamDetails";
import ExamQuestions from "./pages/ExamQuestions";
import ExamStudents from "./pages/ExamStudents";
import ShowExamQA from "./pages/ShowExamQA";

import Home from "./components/Home/Home";
import Subscribe from "./components/Subscribe/Subscribe";
import SubscribtionMain from "./components/SubscribtionMain/SubscribtionMain";
// import SubjectsForSubscribtion from './components/Sections/SubjectForSubscribtion';
import Subject from "./components/Subject/Subject";
import AddStudent from "./pages/signup/signup";
import Sons from "./pages/Sons/Sons";
import Sections from "../src/components/Sections/Sections";
import ShowSections from "./components/Sections/ShowSections";
import ShowSubSections from "./components/SubSections/ShowSubSections";
import SubSections from "./components/SubSections/SubSections";
import ShowSubjects from "./components/Subject/ShowSubjects";
import Teachersm from "./components/Teachers/Teachers";
import Student from "./components/Student/Student";
import Teachers from "./pages/Teachers/Teachers";
import AddLibrarySection from "./pages/AddLibrarySection/AddLibrarySection";
import TeacherDetails from "./pages/TeacherDetails/TeacherDetails";
import EditTeacherDetails from "./pages/EditTeacherDetails/EditTeacherDetails";
import StudentDetails from "./pages/StudentDetails/StudentDetails";
import EditStudentDetails from "./pages/EditStudentDetails/EditStudentDetails";
import SubjectSubsecribtion from "./pages/SubjectSubsecribtion/SubjectSubsecribtion";
import Admins from "./pages/Admins/Admins";
import AddAdmin from "./pages/AddAdmin/AddAdmin";
import EditAdmin from "./pages/EditAdmin/EditAdmin";
import LoginAsAdmin from "./pages/LoginAsAdmin/LoginAsAdmin";
import ShowCommentsSubjects from "./components/CommentsSubject/ShowCommentsSubjects";
import CommentsSubjects from "./components/CommentsSubject/CommentsSubject";
import ShowComment from "./components/CommentsSubject/ShowComment";
import ShowFaqsSubjects from "./components/FaqsSubject/ShowFaqsSubjects";

import FaqsSubjects from "./components/FaqsSubject/FaqsSubject";
import WelcomAdmin from "./pages/WelcomAdmin/WelcomAdmin";
import Missing from "./pages/Missing/Missing";
import UnAuthorized from "./pages/UnAuthorized/UnAuthorized";
import GuardianDetails from "./pages/GuardianDetails/GuardianDetails";
import AddGuardian from "./pages/AddGuardian/AddGuardian";
import EditGuardianDetails from "./pages/EditGuardianDetails/EditGuardianDetails";
import Library from "./pages/Library/Library";
import LibrarySection from "./pages/LibrarySection/LibrarySection";
import EditLibrarySection from "./pages/EditLibrarySection/EditLibrarySection";
import LibrarySectionStudents from "./pages/LibrarySectionStudents/LibrarySectionStudents";
import AddBook from "./pages/AddBook/AddBook";
import EditLibraryBook from "./pages/EditLibraryBook/EditLibraryBook";
import Gurardians from "./pages/Guirdians/Guirdians";
import TestVimeo from "./pages/TestViemeoPlayer/TestViemeoPlayer";
import ShowOffers from "./components/Offers/ShowOffers";
import Offers from "./components/Offers/Offers";
import TeacherSubjects from "./components/TeachersSubject/TeacherSubject";
import ParticipantsPage from "./pages/Chat/ParticipantsPage/ParticipantsPage";
import MessagesBox from "./pages/Chat/MessagesBox/Index";
import StudentSubscribtion from "./components/StudentSubscribtion/StudentSubscribtion";
import SubjectsForStudent from "./components/SubjectsForStudent/SubjectsForSutdent";
import UserOpinions from "./pages/UserOpinions/UserOpinions";
import UserOinionDetails from "./components/userOinionDetails/userOinionDetails";
// import WebsiteTexts from './pages/WebsiteTexts/WebsiteTexts';
// import WebsiteTexts from './components/WebsiteTexts/WebsiteTexts';
// import WebsiteFiles from './components/WebsiteFiles/WebsiteFiles';
import UpdateApplication from "./components/UpdateApplication/UpdateApplication";
import WebsiteTexts from "./components/WebsiteTexts/WebsiteTexts";
import WebsiteFiles from "./components/WebsiteFiles/WebsiteFiles";
import StudentMessages from "./pages/StudentMessages/StudentMessages";
import StudentsMessagesRecord from "./pages/StudentsMessagesRecord/StudentsMessagesRecord";
import UnitSubScribtions from "./pages/UnitSubscrbtions";
import Coupons from "./pages/Coupons";
import { ProfileContext } from './contexts/ProfileContext';
import RegisterOrders from "./pages/RegisterOrders";
import FAQS from "./pages/Faqs";
import FaqsQuestions from "./pages/faqsQuestions";
import QuizesBank from "./pages/QuizesBank";
import CreateQuizBank from "./pages/CreateQuizBank";
import EditQuizBank from "./pages/EditQuizBank";
import ShowQuizBankQuestions from "./pages/ShowQuizBankQuestions";
import AddQuizBankQuestion from "./pages/AddQuizBankQuestion";
import Supderiors from "./pages/Superiors";
import AddSuperiorsSection from "./pages/AddSuperiorsSection";
import SuperiorsList from "./pages/Superiorslist";
import Support from "./components/Support/Support";
import SupportChat from "./components/Support/SupportChat";
import axios from "axios";
import AddBulckQuizBankQuestion from "./pages/AddQuizBankQuestion/bulck";
import AddDynamicQuizBankQuestion from "./pages/AddQuizBankQuestion/AddDynamicQuizBankQuestion";

// lazy loading for all routes

// const UpdateApplication = React.lazy(() => import('./components/UpdateApplication/UpdateApplication'));
// const WebsiteTexts = React.lazy(() => import('./components/WebsiteTexts/WebsiteTexts'));
// const WebsiteFiles = React.lazy(() => import('./components/WebsiteFiles/WebsiteFiles'));
// const UserOinionDetails = React.lazy(() => import('./components/userOinionDetails/userOinionDetails'));
// const UserOpinions = React.lazy(() => import('./pages/UserOpinions/UserOpinions'));
// const SubjectsForStudent = React.lazy(() => import('./components/SubjectsForStudent/SubjectsForSutdent'));
// const StudentSubscribtion = React.lazy(() => import('./components/StudentSubscribtion/StudentSubscribtion'));
// const TeacherSubjects = React.lazy(() => import('./components/TeachersSubject/TeacherSubject'));
// const Offers = React.lazy(() => import('./components/Offers/Offers'));
// const ShowOffers = React.lazy(() => import('./components/Offers/ShowOffers'));
// const TestVimeo = React.lazy(() => import('./pages/TestViemeoPlayer/TestViemeoPlayer'));
// const Gurardians = React.lazy(() => import('./pages/Guirdians/Guirdians'));
// const EditLibraryBook = React.lazy(() => import('./pages/EditLibraryBook/EditLibraryBook'));
// const AddBook = React.lazy(() => import('./pages/AddBook/AddBook'));
// const LibrarySectionStudents = React.lazy(() => import('./pages/LibrarySectionStudents/LibrarySectionStudents'));
// const EditLibrarySection = React.lazy(() => import('./pages/EditLibrarySection/EditLibrarySection'));
// const LibrarySection = React.lazy(() => import('./pages/LibrarySection/LibrarySection'));
// const Library = React.lazy(() => import('./pages/Library/Library'));
// const EditGuardianDetails = React.lazy(() => import('./pages/EditGuardianDetails/EditGuardianDetails'));
// const AddGuardian = React.lazy(() => import('./pages/AddGuardian/AddGuardian'));
// // const GuardianDetails = React.lazy(() => import('./pages/GuardianDetails/GuardianDetails'));
// const UnAuthorized = React.lazy(() => import('./pages/UnAuthorized/UnAuthorized'));
// const Missing = React.lazy(() => import('./pages/Missing/Missing'));
// const WelcomAdmin = React.lazy(() => import('./pages/WelcomAdmin/WelcomAdmin'));
// const FaqsSubjects = React.lazy(() => import('./components/FaqsSubject/FaqsSubject'));
// const ShowFaqsSubjects = React.lazy(() => import('./components/FaqsSubject/ShowFaqsSubjects'));
// const ShowComment = React.lazy(() => import('./components/CommentsSubject/ShowComment'));
// const CommentsSubjects = React.lazy(() => import('./components/CommentsSubject/CommentsSubject'));
// const ShowCommentsSubjects = React.lazy(() => import('./components/CommentsSubject/ShowCommentsSubjects'));
// const LoginAsAdmin = React.lazy(() => import('./pages/LoginAsAdmin/LoginAsAdmin'));
// const EditAdmin = React.lazy(() => ('./pages/EditAdmin/EditAdmin'));
// const AddAdmin = React.lazy(() => import('./pages/AddAdmin/AddAdmin'));
// const Admins = React.lazy(() => import('./pages/Admins/Admins'));
// const SubjectSubsecribtion = React.lazy(() => import('./pages/SubjectSubsecribtion/SubjectSubsecribtion'));
// const EditStudentDetails = React.lazy(() => import('./pages/EditStudentDetails/EditStudentDetails'));
// const StudentDetails = React.lazy(() => import('./pages/StudentDetails/StudentDetails'));
// const EditTeacherDetails = React.lazy(() => import('./pages/EditTeacherDetails/EditTeacherDetails'));
// const TeacherDetails = React.lazy(() => import('./pages/TeacherDetails/TeacherDetails'));
// const AddLibrarySection = React.lazy(() => import('./pages/AddLibrarySection/AddLibrarySection'));
// const Teachers = React.lazy(() => import('./pages/Teachers/Teachers'));
// const Student = React.lazy(() => import('./components/Student/Student'));
// const Teachersm = React.lazy(() => import('./components/Teachers/Teachers'));
// const ShowSubjects = React.lazy(() => import('./components/Subject/ShowSubjects'));
// const SubSections = React.lazy(() => import('./components/SubSections/SubSections'));
// const ShowSubSections = React.lazy(() => import('./components/SubSections/ShowSubSections'));
// const ShowSections = React.lazy(() => import('./components/Sections/ShowSections'));
// const Sections = React.lazy(() => import('../src/components/Sections/Sections'));
// const Sons = React.lazy(() => import('./pages/Sons/Sons'));
// const AddStudent = React.lazy(() => import('./pages/signup/signup'));
// const Subject = React.lazy(() => import('./components/Subject/Subject'));
// const SubscribtionMain = React.lazy(() => import('./components/SubscribtionMain/SubscribtionMain'));
// const Subscribe = React.lazy(() => import('./components/Subscribe/Subscribe'));
// const Home = React.lazy(() => import('./components/Home/Home'));
// const CallsRecord = React.lazy(() => './pages/callsRecord/callsRecord');

// import WebsiteTexts from './components/WebsiteTexts/WebsiteTexts';

function ClearPaginationsDataFromLocalStorage() {
    localStorage.removeItem("allstudents_pag");
    localStorage.removeItem("librarysection_pag");
    localStorage.removeItem("activestudents_pag");
    localStorage.removeItem("guardian_pag");
    localStorage.removeItem("library_pag");
    localStorage.removeItem("teachers_pag");
    localStorage.removeItem("admins_pag");
    localStorage.removeItem("offers_pag");
    localStorage.removeItem("useropinion_pag");
    localStorage.removeItem("unactivestudents_pag");
    localStorage.removeItem("newstudents_pag");
    localStorage.removeItem("sections_pag");
    localStorage.removeItem("subsections_pag");
    localStorage.removeItem("comments_pag");
    localStorage.removeItem("typeOfTable");
    localStorage.removeItem("callRecords_page");
    localStorage.removeItem("superiors_sections_pag");
    localStorage.removeItem("superiorslist_sections_pag");
}


function App() {
    const userData = useSelector((state) => state.Login);
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        if (
            userData.token == "" ||
            userData.token == null ||
            userData.token == undefined
        ) {
            navigate("/loginasadmin");
        }
        else {
            axios
                .get(`${process.env.REACT_APP_API_URL}/profile`, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                })
                .then((response) => {
                    const profileData = response.data;
                    setProfileData(profileData);
                    console.log('Profile Data:', profileData);
                })
                .catch((error) => {
                    console.error('Error fetching profile:', error);
                    if (error.response && error.response.status === 401) {
                        navigate('/loginasadmin');
                    }
                });
        }

        ClearPaginationsDataFromLocalStorage();


    }, []);

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ProfileContext.Provider value={profileData}>

                <Routes>
                    {/* <Route path='/addteacher' element={<Teachersm/>} />
    <Route path="/" element={<Home />} />
    <Route path='/students' element={<Student/>} />
    <Route path="/teachers" element={<Teachers/>} />
    <Route path="/SubjectSubsecribtion" element={<SubjectSubsecribtion />} />
    <Route path="/addlibrarysection" element={<AddLibrarySection />} />
    <Route path="/teacher/:id" element={<TeacherDetails />} />
    <Route path="/editteacherdetails/:id" element={<EditTeacherDetails />} />
    <Route path="/student/:id" element={<StudentDetails />} />
    <Route path="/editstudentdetails/:id" element={<EditStudentDetails />} />
    <Route path="/admins" element={<Admins />} />
    <Route path="/addadmin" element={<AddAdmin />} />
    <Route path="/editadmin/:id" element={<EditAdmin />} />
    <Route path="/loginAsAdmin" element={<LoginAsAdmin />} />

    <Route path="/welcomAdmin" element={<WelcomAdmin />} /> */}

                    <Route path="*" element={<Missing />} />

                    {/*
<Route element={<RequireAuth AllowedRoles={[4,5,6,7]} />} >
  <Route path="/welcomAdmin" element={<WelcomAdmin />} />
</Route> */}

                    <Route path="/" element={<WelcomAdmin />} />

                    <Route path="/unauthorized" element={<UnAuthorized />} />

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/homepage" element={<Home />} />
                    </Route>
                    <Route element={<RequireAuth AllowedRoles={[10,6]} />}>
                        <Route path="/support" element={<Support />} />
                    </Route>
                    <Route element={<RequireAuth AllowedRoles={[10,6]} />}>
                        <Route path="/support/chat/:userId" element={<SupportChat />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/websitetexts" element={<WebsiteTexts />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/superiors" element={<Supderiors />} />
                    </Route>
                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/superiorslist/:id" element={<SuperiorsList />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route
                            path="/addSuperiorsSection"
                            element={<AddSuperiorsSection />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/websitefiles" element={<WebsiteFiles />} />
                    </Route>
                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/units_subscribtions" element={<UnitSubScribtions />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/coupons" element={<Coupons />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/updateapplication" element={<UpdateApplication />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route
                            path="/students_messagesRecord"
                            element={<StudentsMessagesRecord />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/registerOrders" element={<RegisterOrders />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 9]} />}>
                        <Route path="callsRecord/:id" element={<CallsRecord />} />
                    </Route>

                    {/*
  // Role_id is => 7
  He is the main admin
  all routes are opened for him

  */}

                    {/*
  // Role_id is => 6
  He is the Users admin
  He can only mange the user account
  */}

                    <Route path="/testvimeo" element={<TestVimeo />} />

                    {/*
  // Role_id is => 5
  He is the finisial admin
  He can mange the payments subs
  */}

                    {/*
  // Role_id is => 4
  He is the content admin
  He can manage the content of website
  */}

                    {/* Admins */}
                    <Route element={<RequireAuth AllowedRoles={[7]} />}>
                        <Route path="/admins" element={<Admins />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[7]} />}>
                        <Route path="/addadmin" element={<AddAdmin />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[7]} />}>
                        <Route path="/editadmin/:id" element={<EditAdmin />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[7]} />}>
                        <Route path="/students_messages" element={<StudentMessages />} />
                    </Route>

                    {/* Login and welcome */}

                    <Route path="/loginAsAdmin" element={<LoginAsAdmin />} />

                    <Route path="/welcomAdmin" element={<WelcomAdmin />} />

                    {/* Guardians */}
                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/guirdians" element={<Gurardians />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/guardian/:id" element={<GuardianDetails />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/addguardian" element={<AddGuardian />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route
                            path="/editguardiandetails/:id"
                            element={<EditGuardianDetails />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/sons/:guardianID" element={<Sons />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route path="/exams" element={<Exams />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/faqs" element={<FAQS />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/faqsQuestions/:id" element={<FaqsQuestions />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route path="/showExams/:id" element={<ShowExams />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route path="/examDetails/:id" element={<ShowExamDetails />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route
                            path="/addTraditionalExam/:id"
                            element={<AddTraditionalExam />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/examquestions/:id" element={<ExamQuestions />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route path="/examStudents/:id" element={<ExamStudents />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7, 8]} />}>
                        <Route path="/showExamQa/:id/:examId" element={<ShowExamQA />} />
                    </Route>

                    {/* Students */}
                    <Route element={<RequireAuth AllowedRoles={[6, 7, 9]} />}>
                        <Route path="/students" element={<Student />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/addstudent" element={<AddStudent />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/student/:id" element={<StudentDetails />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route
                            path="/editstudentdetails/:id"
                            element={<EditStudentDetails />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/SubjectStudent/:id" element={<SubjectsForStudent />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route path="/quizes-bank" element={<QuizesBank />} />
                    </Route>
                    
                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route path="/quizes-bank/add-questions/:quizId" element={<AddDynamicQuizBankQuestion />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route
                            path="/create_automated_quiz_bank"
                            element={<CreateQuizBank />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route path="/quizes-bank/edit/:QuizId" element={<EditQuizBank />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route
                            path="/quizes-bank/show/:QuizId"
                            element={<ShowQuizBankQuestions />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 6, 7, 8]} />}>
                        <Route
                            path="/quizes-bank/add-question/:QuizId"
                            element={<AddQuizBankQuestion />}
                        />
                    </Route>

                    {/* Teachers */}
                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/addteacher" element={<Teachersm />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/teachers" element={<Teachers />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route path="/teacher/:id" element={<TeacherDetails />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
                        <Route
                            path="/editteacherdetails/:id"
                            element={<EditTeacherDetails />}
                        />
                    </Route>

                    {/* Library */}
                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/library" element={<Library />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/userOpinions" element={<UserOpinions />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route
                            path="/useropiniondetails/:opinionId"
                            element={<UserOinionDetails />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/addlibrarysection" element={<AddLibrarySection />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/librarySection/:id" element={<LibrarySection />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route
                            path="/editlibrarysection/:id"
                            element={<EditLibrarySection />}
                        />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/addbook/:id" element={<AddBook />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route path="/editlibrarybook/:id" element={<EditLibraryBook />} />
                    </Route>

                    <Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
                        <Route
                            path="/librarySectionStudents/:sectionId"
                            element={<LibrarySectionStudents />}
                        />
                    </Route>

                    {/* create and show subscribtions ,subjecet */}
                    <Route element={<RequireAuth AllowedRoles={[5, 6, 7]} />}>
                        <Route path="/mainSubscribtions" element={<SubscribtionMain />} />
                        <Route
                            path="/mainsubscribtion/:id"
                            element={<SubjectSubsecribtion />}
                        />
                        <Route path="/subscribe/:id" element={<Subscribe />} />
                        <Route path="/subscribe" element={<Subscribe />} />
                    </Route>
                    showSubSections
                    {/* <Route element={<RequireAuth AllowedRoles={[6,7]} />} >

</Route> */}

                    <Route element={<RequireAuth AllowedRoles={[5, 4, 7]} />}>
                        <Route path="/addsections" element={<Sections />} />

                        <Route path="/addsections/:id" element={<Sections />} />

                        <Route path="/showSections" element={<ShowSections />} />

                        <Route path="/showSections/:id" element={<ShowSubSections />} />

                        <Route path="/showSubSections/:id" element={<ShowSubSections />} />

                        <Route path="/addSubSections" element={<SubSections />} />

                        <Route path="/addSubSections/:id" element={<SubSections />} />

                        <Route path="/subject" element={<Subject />} />

                        <Route path="/subject/:id" element={<Subject />} />

                        <Route path="/showSubjects/:id" element={<ShowSubjects />} />

                        <Route path="/showComments/:id" element={<ShowCommentsSubjects />} />

                        <Route path="/addComments" element={<CommentsSubjects />} />

                        <Route path="/addComments/:id" element={<CommentsSubjects />} />

                        <Route path="/showComment" element={<ShowComment />} />

                        <Route path="/showFaqs/:id" element={<ShowFaqsSubjects />} />

                        <Route path="/addFaqs" element={<FaqsSubjects />} />

                        <Route path="/addFaqs/:id" element={<FaqsSubjects />} />

                        <Route path="/showOffers" element={<ShowOffers />} />
                        <Route path="/addOffers" element={<Offers />} />
                        <Route path="/addOffers/:id" element={<Offers />} />
                        <Route path="/teahcersSubject/:id" element={<TeacherSubjects />} />

                        <Route path="/subjects/:subjectId/teachers/:teacherId/chat/participants" element={<ParticipantsPage />} />
                        <Route path="/subjects/:subjectId/teachers/:teacherId/chat/participants/:userId" element={<MessagesBox />} />

                        <Route
                            path="/studentSubscribtion/:id"
                            element={<StudentSubscribtion />}
                        />
                    </Route>
                </Routes>
            </ProfileContext.Provider>
        </QueryClientProvider>
    );
}

export default App;

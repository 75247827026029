import React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import StudentsMessagesRecordTable from "../../components/ConfirmRequest/StudentsMessagesRecordTable";
// import Button from '../../components/button';
// import chalks from '../../assets/icons/chalks.png';
// import './StudentMessages.css';
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import fetchAllSmsMessages from "../../redux-toolkit/api/fetchAllSmsMessages";

const StudentsMessagesRecord = () => {
  const [page, setPage] = useState(1);
  const { isLoading, isError, data, error } = useQuery(
    ["students", page],
    fetchAllSmsMessages
  );

  function handlePageClick(data) {
    localStorage.setItem("studentMessagesRecord", data.selected);
    setPage(data.selected);
  }

  return (
    <Layout>
      <div className="profileScreen container ">
        <div className="profileScreen__content">
          <h1>سجل الرسائل</h1>

          <h2 className="text-center my-3 border shadow rounded p-4">
            عدد الرسائل المستهلكة من الباقة حتى الآن:{" "}
            {data?.data?.total_sms_count}
          </h2>
          <StudentsMessagesRecordTable
            data={data?.data?.messages?.data}
            isLoading={isLoading}
          />

          <div className="my-4">
            <Pagination
              data={data?.data?.messages}
              handlePageClick={handlePageClick}
              page={page}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StudentsMessagesRecord;

import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import ToastSuccessError from "../../components/ToastSuccessError/ToastSucessError";
import ShowallautomatedfinalQuizLogic from "./QuizesBankLogic";

export default function QuizesBank() {
  const modaleRef = useRef(null);
  const [selectedQuiz, setSelectedQuiz] = useState({});
  const [errorMessage, setErroreMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [querySearch, setQuerySearch] = useState("");
  const [isAddClone, setIsAddClone] = useState(false);
  const [selectedQuizForClone, setSelectedQuizForClone] = useState({});
  const [isloading, setIsloading] = useState(false);

  const navigate = useNavigate();

  const {
    IsError,
    dispatch,
    isError,
    data,
    isLoading,
    getDatafrom,
    setPage,
    page,
    refetch,
  } = ShowallautomatedfinalQuizLogic(querySearch);
  // console.log(final_automated_quiz_by_unit_id);

  const deleteQuizeBank = () => {
    // delete the quiz

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_automated_quiz/${selectedQuiz.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => {
        setSuccessMessage("تم حذف الاختبار بنجاح");
        refetch();
        modaleRef.current.style.display = "none";
        modaleRef.current.click();
      })
      .catch(() => {
        setErroreMessage("حدثت مشكلة ما يرجى المحاولة مرة أخرى");
      });
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleSearchStudent = (e) => {
    const value = e.target.value;
    setQuerySearch(value);
  };

  const devouncedFn = debounce(handleSearchStudent, 500);

  const handleAddClone = () => {
    setIsloading(true);

    const dataToSend = {
      cloned_automated_quiz_id: selectedQuizForClone.id,
      new_automated_quiz_id: selectedQuiz.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/automated-quiz/clone`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => {
        setSuccessMessage(
          `تم انشاء نسخة من اسئلة الاختبار ${selectedQuizForClone.quiz_name} للاختبار ${selectedQuiz.quiz_name} بنجاح`
        );
      })
      .catch(() => {
        setErroreMessage("حدثت مشكلة ما يرجى المحاولة مرة أخرى");
      })
      .finally(() => {
        setSelectedQuiz({});
        setSelectedQuizForClone({});
        setSelectedQuizForClone(false);
        setIsloading(false);
        setIsAddClone(false);
      });
  };

  return (
    <Layout>
      <ToastSuccessError
        ErrorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErroreMessage}
        setSuccessMessage={setSuccessMessage}
      />

      <div className="container all">
        <h1 className="pt-0 mb-4 pb-4 pt-3">بنك الاختبارات</h1>
        <input
          type="text"
          // onFocus={() => handleOnFucusInput()}
          // ref={inputSearchRef}
          placeholder="اكتب هنا للبحث عن اختبار عن طريق الاسم"
          onChange={(e) => devouncedFn(e)}
          className="shadow w-100 main-input border-0 mb-4 rounded"
        />
        <Link className="btn btn-success" to="/create_automated_quiz_bank">
          أضف اختبار
        </Link>

        {isAddClone && (
          <div className="mb-4">
            {!selectedQuizForClone.quiz_name && (
              <div className="fs-5">
                يرجى اختيار الاختبار المراد نسخ اسئلته لمحتوى الاختبار (
                {selectedQuiz.quiz_name})
              </div>
            )}

            {isAddClone && (
              <button
                className="btn btn-danger d-block my-2 mx-auto"
                onClick={() => setIsAddClone(false)}
              >
                إلغاء عملية النسخ
              </button>
            )}

            {selectedQuizForClone.quiz_name && (
              <div className="w-100 text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => handleAddClone()}
                >
                  إضافة نسخة من الاختبار ({selectedQuizForClone.quiz_name})
                  للاختبار ({" " + selectedQuiz.quiz_name + " "})
                  {isloading && (
                    <div className="mx-2">
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
        )}

        {
          <div
            class=""
            style={{
              margin: "20px auto !important",
              width: "100%",
              overflow: "auto",
            }}
          >
            <table className=" table table-bordered table-striped w-75 mx-auto  ">
              <thead>
                <tr>
                  {isAddClone && <th></th>}
                  <th className="text-center align-middle"> اسم الاختبار </th>
                  <th className="text-center align-middle"> مدة الاختبار </th>
                  <th className="text-center align-middle"> نقاط الاختبار </th>
                  {!isAddClone && (
                    <th className="text-center align-middle">
                      العمليات المتاحة{" "}
                    </th>
                  )}
                </tr>
              </thead>
              {data?.data && (
                <tbody>
                  {data?.data?.map((Quiz) => (
                    <tr key={Quiz.id}>
                      {isAddClone && (
                        <td className="text-center align-middle">
                          <input
                            type={"checkbox"}
                            checked={selectedQuizForClone.id == Quiz.id}
                            onClick={() => setSelectedQuizForClone(Quiz)}
                          />
                        </td>
                      )}

                      <td className="text-center align-middle">
                        {" "}
                        {Quiz.quiz_name}{" "}
                      </td>
                      <td className="text-center align-middle">
                        {" "}
                        {Quiz.quiz_duration}{" "}
                      </td>
                      <td className="text-center align-middle">
                        {" "}
                        {Quiz.quiz_points}{" "}
                      </td>
                      {!isAddClone && (
                        <td className="text-center align-middle">
                          {" "}
                          <Link to={`edit/${Quiz.id}`} className="btn btn-dark">
                            {" "}
                            <div className="px-3">تعديل </div>
                          </Link>{" "}
                          <button
                            onClick={() => setSelectedQuiz(Quiz)}
                            className="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            {" "}
                            <div className="px-3">حذف </div>
                          </button>{" "}
                          {Quiz.file ? (
                            <>
                              <button
                                // to={`add-question/${Quiz.id}`}
                                className="btn btn-success"
                                onClick={() => {
                                  navigate(
                                    `/quizes-bank/add-questions/${Quiz.id}`
                                  );
                                }}
                              >
                                {" "}
                                <div className="px-3">أضف أسئلة </div>
                              </button>{" "}
                            </>
                          ) : (
                            <>
                              <button
                                // to={`add-question/${Quiz.id}`}
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLabeladdQuestions"
                                onClick={() => {
                                  if (Quiz.file) {
                                  } else setSelectedQuiz(Quiz);
                                }}
                              >
                                {" "}
                                <div className="px-3">أضف أسئلة </div>
                              </button>{" "}
                            </>
                          )}{" "}
                          <Link
                            to={`show/${Quiz.id}`}
                            className="btn btn-success"
                          >
                            {" "}
                            <div className="px-3">عرض الأسئلة </div>
                          </Link>{" "}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            {isLoading && (
              <div class="d-flex my-5 justify-content-center w-100">
                <div class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            <div style={{ margin: "30px auto" }} />
          </div>
        }
        {data?.data?.total < 10 ? (
          <> </>
        ) : (
          <Pagination
            data={data}
            handlePageClick={handlePageClick}
            page={page}
          />
        )}
      </div>

      <div
        class="modal fade"
        ref={modaleRef}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                هل أنت متأكد من عملية الحذف ؟
              </h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إلغاء
              </button>
              <button
                type="button"
                ref={modaleRef}
                class="btn btn-primary"
                onClick={() => deleteQuizeBank()}
              >
                تأكيد
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        ref={modaleRef}
        id="exampleModalLabeladdQuestions"
        tabindex="-1"
        aria-labelledby="exampleModalLabeladdQuestions"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                الرجاء أختيار طريقة إضافة الاسئلة قبل البدء بالعملية{" "}
              </h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer d-flex flex-wrap justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={modaleRef}
              >
                إلغاء
              </button>

              <button
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-dark"
                onClick={() => {
                  navigate(`add-question/${selectedQuiz.id}`);
                  modaleRef.current.style.dispatch = "none";
                  modaleRef.current.click();
                }}
              >
                إضافة أسئلة جديدة
              </button>

              <button
                type="button"
                class="w-100 btn btn-primary"
                onClick={() => {
                  setIsAddClone(true);
                  modaleRef.current.style.dispatch = "none";
                  modaleRef.current.click();
                }}
              >
                إضافة نسخة من اختبار مضاف مسبقا
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

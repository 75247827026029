import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ConfirmRequest from "../../components/ConfirmRequest/ConfirmRequest";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import SubjectAchivement from "../../components/SubjectAchivment/index";
import fetchTeachers from "../../redux-toolkit/api/fetchTeachers";
import AdminsTable from "../../components/ConfirmRequest/AdminsTable";
// import './Admins.css';
import { NavLink, useParams } from "react-router-dom";
import group from "../../assets/AboutUs/Group 408.png";
import fetchAdmins from "../../redux-toolkit/api/fetchAdmins";
import CallsRecordTable from "../../components/ConfirmRequest/CallsRecordTable";
import "./callsRecord.css";
import fetchStudentCallsRecord from "../../redux-toolkit/api/fetchStudentCallsRecord";
import uuid from "react-uuid";

const CallsRecord = (props) => {
  const { id } = useParams();
  const [showCalls, setShowCalls] = useState(true);
  // console.log('id student student', id);
  const [page, setPage] = useState(0);
  const {
    isLoading,
    isError,
    data,
    error,
    refetch: refetchCallsRecords,
  } = useQuery(["studentcallsRecords", page, id], fetchStudentCallsRecord);

  function handlePageClick(data) {
    // localStorage.setItem('calls_record_pag' , data.selected)
    setPage(data.selected);
  }

  return (
    <Layout>
      <div className="all d-flex justify-content-around ">
        <button
          className={`btn ${showCalls ? "btn-success" : "btn-dark"} shadow-sm `}
          onClick={() => setShowCalls(true)}
        >
          سجل الرسائل{" "}
        </button>
        <button
          className={`btn ${!showCalls ? "btn-success" : "btn-dark"} shadow-sm`}
          onClick={() => setShowCalls(false)}
        >
          إنجاز الطالب
        </button>
      </div>

      {showCalls ? (
        <div className=" callsRecordsContainer container  all">
          <h1
            className="pt-0 my-5 pb-4 pt-3"
            style={
              {
                // borderBottom: '1px solid green'
              }
            }
          >
            سجل مكالمات ومتابعة الطالب <strong>{data?.result}</strong>
          </h1>

          <div className=" mb-5">
            {
              <CallsRecordTable
                data={data?.data}
                isLoading={isLoading}
                refetch={refetchCallsRecords}
              />
            }
          </div>
          {data?.total < 2 ? (
            <> </>
          ) : (
            <Pagination
              data={data?.data}
              handlePageClick={handlePageClick}
              page={page}
            />
          )}
        </div>
      ) : (
        <div className="callsRecordsContainer container all">
          <h1
            className="pt-0 my-3 pb-4 pt-3"
            style={
              {
                // borderBottom: '1px solid green'
              }
            }
          >
            {" "}
            إنجاز الطالب <strong>{data?.result}</strong>
          </h1>

          <div className="infos justify-content-evenly">
            <div className="circleInfoAll">
              <span> عدد ساعات الدراسة الكلية للدروس المتاحة: </span>
              <span>{data?.study_hours?.total_subject_lessons_duration}</span>
            </div>

            <div className="circleInfoAll">
              <span>عدد الساعات المنجزة من قبلك: </span>
              <span>{data?.study_hours?.total_student_duration}</span>
            </div>
          </div>

          <div className="infos">
            {data?.student_subjects?.map((sub, idx) => {
              return (
                <SubjectAchivement
                  totalHours={sub?.total_subject_duration}
                  achivedPercentage={sub.achivment_percentage}
                  hours={sub.total_student_duration}
                  name={sub.subject_name}
                  key={uuid()}
                />
              );
            })}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CallsRecord;
